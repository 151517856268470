<div class="page-content">
  <form class="add-item-row" (submit)="onAddItem()" *ngIf="showAddItemForm">
    <mat-form-field class="full-width no-padding-bottom" appearance="outline">
      <input
        matInput
        placeholder="{{ addItemLabel | i18nextCap }}"
        [formControl]="newItemControl"
      />
    </mat-form-field>
    <button
      mat-flat-button
      color="primary"
      [disabled]="!newItemControl.valid || !newItemControl.dirty"
    >
      {{ 'add' | i18nextCap }}
    </button>
  </form>

  <mat-divider *ngIf="showAddItemForm"></mat-divider>

  <div class="height-limit">
    <mat-card
      *ngFor="let item of items$ | async; let i = index"
      class="full-width item-card"
    >
      <mat-card-content class="full-width item-card-content" #cardContent>
        <form
          #f="ngForm"
          (ngSubmit)="onEditItem(item.id, i, f)"
          class="item-card-content-form"
        >
          <div #titleCard [attr.index]="i" class="full-width full-height">
            <div *ngIf="i == editingNameIndex">
              <mat-form-field
                class="full-width no-padding no-padding-bottom"
                appearance="outline"
              >
                <input
                  name="name"
                  ngModel
                  matInput
                  [ngModel]="item[titlePropertyName]"
                  class="edit-input"
                />
              </mat-form-field>
            </div>

            <div *ngIf="i != editingNameIndex">
              {{ item[titlePropertyName] | i18nextCap }}
            </div>
          </div>
          <div class="options">
            <button
              mat-icon-button
              (click)="enableEditName(i)"
              *ngIf="i != editingNameIndex && canEditItemName"
            >
              <div class="icon blue-icon">
                <fa-icon [icon]="faPen"></fa-icon>
              </div>
            </button>

            <button
              mat-icon-button
              *ngIf="i == editingNameIndex"
              type="submit"
              #submitEditLossMotive
            >
              <div class="icon green-icon">
                <fa-icon [icon]="faFloppyDisk"></fa-icon>
              </div>
            </button>

            <button
              mat-icon-button
              (click)="onDeleteItem(item.id, i)"
              type="button"
              *ngIf="canDeleteItemName"
            >
              <div class="icon red-icon">
                <fa-icon [icon]="faTrashCan"></fa-icon>
              </div>
            </button>

            <mat-slide-toggle
              *ngIf="canToggleItem"
              (toggleChange)="onToggleItem(item.id, item.active)"
              [checked]="item.active"
            ></mat-slide-toggle>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
