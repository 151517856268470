<form [formGroup]="formGroup" class="form-container">
  <ng-container *ngFor="let field of formFields">
    <div
      class="form-field"
      [ngClass]="'col-' + field.size"
      [ngSwitch]="field.type"
      *ngIf="!field.hidden"
    >
      <div *ngSwitchCase="'text'">
        <div class="text-div-field">
          <mat-form-field class="d-block full-width" appearance="outline">
            <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
            <input
              matInput
              [name]="field.name"
              [formControlName]="field.name"
              (blur)="onSelectChange($event)"
              (keyup.enter)="onSelectChange($event)"
              [matTooltip]="field.matTooltip | i18nextCap"
              [attr.data-testid]="this.field?.dataTestId"
            />
            <mat-hint *ngIf="field.helperText"
              >{{ field.helperText | i18nextCap }}
            </mat-hint>
            <mat-error *ngIf="formGroup.controls[field.name]?.errors?.email"
              >{{ 'validation:emailMustBeProperValue' | i18nextCap }}
            </mat-error>
            <mat-error *ngIf="formGroup.controls[field.name]?.errors?.required"
              >{{ 'validation:youMustEnterAValue' | i18nextCap }}
            </mat-error>
          </mat-form-field>
          <button
            *ngIf="field.button"
            mat-icon-button
            (click)="this.field.button.onClick()"
            color="primary"
            type="button"
            [disabled]="
              this.field.button.disabled$
                ? (this.field.button.disabled$ | async)
                : undefined
            "
          >
            <mat-icon>{{ field.button.iconName }}</mat-icon>
          </button>
        </div>
      </div>

      <div *ngSwitchCase="'textarea'">
        <mat-form-field class="d-block full-width" appearance="outline">
          <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
          <textarea
            matInput
            [name]="field.name"
            [formControlName]="field.name"
            (blur)="onSelectChange($event)"
            (keyup.enter)="onSelectChange($event)"
          ></textarea>
          <mat-error
            >{{ 'validation:youMustEnterAValue' | i18nextCap }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'checkbox'">
        <mat-checkbox
          class="full-width"
          [name]="field.name"
          [formControlName]="field.name"
          (change)="onSelectChange($event)"
          >{{ 'forms:' + field.label | i18nextCap }}
        </mat-checkbox>
      </div>

      <div *ngSwitchCase="'number'">
        <mat-form-field class="d-block full-width" appearance="outline">
          <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
          <input
            class="number"
            matInput
            type="number"
            min="0"
            step="1"
            [name]="field.name"
            [formControlName]="field.name"
            (blur)="onInputChange()"
            (keyup.enter)="onInputChange()"
          />
          <mat-error
            >{{ 'validation:youMustEnterAValue' | i18nextCap }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'select'">
        <crm-form-select
          class="full-width"
          [formGroup]="formGroup"
          [field]="field"
          (selectionChange)="onSelectChange($event)"
        ></crm-form-select>
      </div>
      <div *ngSwitchCase="'multiple-select'">
        <crm-form-select
          class="full-width"
          [formGroup]="formGroup"
          [field]="field"
          [multiple]="true"
          (selectionChange)="onSelectChange($event)"
        ></crm-form-select>
      </div>
      <div *ngSwitchCase="'fulldate'">
        <crm-form-date
          class="full-width"
          [formGroup]="formGroup"
          [field]="field"
          (selectionChange)="onSelectChange($event)"
        ></crm-form-date>
      </div>
      <div *ngSwitchCase="'slide'">
        <mat-slide-toggle
          class="full-width"
          [name]="field.name"
          [formControlName]="field.name"
          (change)="onSelectChange($event)"
          color="primary"
        >
          {{ 'forms:' + field.label | i18nextCap }}
        </mat-slide-toggle>
      </div>
      <div *ngSwitchCase="'autocomplete'">
        <crm-form-autocomplete
          class="full-width"
          [formGroup]="formGroup"
          [field]="field"
        ></crm-form-autocomplete>
      </div>
      <div *ngSwitchCase="'chips-autocomplete'">
        <crm-form-chips-autocomplete
          [formGroup]="formGroup"
          [field]="field"
        ></crm-form-chips-autocomplete>
      </div>
      <div *ngSwitchCase="'date'">
        <crm-form-date
          class="full-width"
          [dateOnly]="true"
          [formGroup]="formGroup"
          [field]="field"
          (selectionChange)="onSelectChange($event)"
        ></crm-form-date>
      </div>
      <div *ngSwitchCase="'upload'">
        <mat-form-field class="d-block full-width" appearance="outline">
          <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
          <input
            matInput
            [name]="field.name"
            [formControlName]="field.name"
            class="icon"
          />
          <input
            style="display: none"
            type="file"
            (change)="uploadPDF($event, field.name)"
            #fileInput
          />
          <button
            mat-icon-button
            matSuffix
            type="button"
            color="primary"
            (click)="fileInput.click()"
          >
            <mat-icon>publish</mat-icon>
          </button>
          <mat-error
            >{{ 'validation:youMustEnterAValue' | i18nextCap }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="'price'">
        <mat-form-field class="d-block full-width" appearance="outline">
          <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
          <span class="currency" matSuffix>€ &nbsp;</span>
          <input
            class="price"
            matInput
            type="number"
            [name]="field.name"
            [formControlName]="field.name"
          />
          <mat-error
            >{{ 'validation:youMustEnterAValue' | i18nextCap }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngSwitchCase="'images'">
        <crm-input-images
          class="full-width"
          [field]="field"
          [formGroup]="formGroup"
        ></crm-input-images>
      </div>
      <div *ngSwitchCase="'autocomplete-list'">
        <crm-form-autocomplete-list
          [formGroup]="formGroup"
          [field]="field"
        ></crm-form-autocomplete-list>
      </div>

      <div *ngSwitchDefault class="field-not-found">
        {{ field.name }}Field Not Found
      </div>
    </div>
  </ng-container>
</form>

<!-- Aqui -->

<!-- <form [formGroup]="formGroup">
  <ng-container *ngFor="let field of row; let i = index">
    <mat-grid-tile
      *ngIf="
        ![
          'text',
          'textarea',
          'number',
          'fulldate',
          'select',
          'multiple-select',
          'checkbox',
          'slide',
          'autocomplete',
          'date',
          'upload',
          'price',
          'images'
        ].includes(field.type)
      "
      [colspan]="field.size"
      [style.background]="'lightblue'"
      >{{ i }} {{ field.size }} {{ field.type }}
    </mat-grid-tile>
    <ng-container [ngSwitch]="field.type" *ngIf="!field.hidden">
      <mat-grid-tile
        *ngSwitchCase="'textarea'"
        [rowspan]="2"
        [colspan]="field.size"
      >
        <mat-form-field class="d-block full-width" appearance="outline">
          <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
          <textarea
            matInput
            [name]="field.name"
            [formControlName]="field.name"
            (blur)="onSelectChange($event)"
            (keyup.enter)="onSelectChange($event)"
          ></textarea>
          <mat-error
            >{{ 'validation:youMustEnterAValue' | i18nextCap }}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'checkbox'" [colspan]="field.size">
        <mat-checkbox
          class="full-width"
          [name]="field.name"
          [formControlName]="field.name"
          (change)="onSelectChange($event)"
          >{{ 'forms:' + field.label | i18nextCap }}
        </mat-checkbox>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'number'" [colspan]="field.size">
        <mat-form-field class="d-block full-width" appearance="outline">
          <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
          <input
            class="number"
            matInput
            type="number"
            min="0"
            step="1"
            [name]="field.name"
            [formControlName]="field.name"
            (blur)="onInputChange()"
            (keyup.enter)="onInputChange()"
          />
          <mat-error
            >{{ 'validation:youMustEnterAValue' | i18nextCap }}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'select'" [colspan]="field.size">
        <crm-form-select
          class="full-width"
          [formGroup]="formGroup"
          [field]="field"
          (selectionChange)="onSelectChange($event)"
        ></crm-form-select>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'multiple-select'" [colspan]="field.size">
        <crm-form-select
          class="full-width"
          [formGroup]="formGroup"
          [field]="field"
          [multiple]="true"
          (selectionChange)="onSelectChange($event)"
        ></crm-form-select>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'fulldate'" [colspan]="field.size">
        <crm-form-date
          class="full-width"
          [formGroup]="formGroup"
          [field]="field"
          (selectionChange)="onSelectChange($event)"
        ></crm-form-date>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'slide'" [colspan]="field.size">
        <mat-slide-toggle
          class="full-width"
          [name]="field.name"
          [formControlName]="field.name"
          (change)="onSelectChange($event)"
          color="primary"
        >
          {{ 'forms:' + field.label | i18nextCap }}
        </mat-slide-toggle>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'autocomplete'" [colspan]="field.size">
        <crm-form-autocomplete
          class="full-width"
          [formGroup]="formGroup"
          [field]="field"
        ></crm-form-autocomplete>
      </mat-grid-tile>
      <mat-grid-tile
        *ngSwitchCase="'chips-autocomplete'"
        [colspan]="field.size"
      >
        <crm-form-chips-autocomplete
          [formGroup]="formGroup"
          [field]="field"
        ></crm-form-chips-autocomplete>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'date'" [colspan]="field.size">
        <crm-form-date
          class="full-width"
          [dateOnly]="true"
          [formGroup]="formGroup"
          [field]="field"
          (selectionChange)="onSelectChange($event)"
        ></crm-form-date>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'upload'" [colspan]="field.size">
        <mat-form-field class="d-block full-width" appearance="outline">
          <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
          <input
            matInput
            [name]="field.name"
            [formControlName]="field.name"
            class="icon"
          />
          <input
            style="display: none"
            type="file"
            (change)="uploadPDF($event, field.name)"
            #fileInput
          />
          <button
            mat-icon-button
            matSuffix
            type="button"
            color="primary"
            (click)="fileInput.click()"
          >
            <mat-icon>publish</mat-icon>
          </button>
          <mat-error
            >{{ 'validation:youMustEnterAValue' | i18nextCap }}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'price'" [colspan]="field.size">
        <mat-form-field class="d-block full-width" appearance="outline">
          <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
          <span class="currency" matSuffix>€ &nbsp;</span>
          <input
            class="price"
            matInput
            type="number"
            [name]="field.name"
            [formControlName]="field.name"
          />
          <mat-error
            >{{ 'validation:youMustEnterAValue' | i18nextCap }}
          </mat-error>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile
        *ngSwitchCase="'images'"
        [rowspan]="3"
        [colspan]="field.size"
      >
        <crm-input-images
          class="full-width"
          [field]="field"
          [formGroup]="formGroup"
        ></crm-input-images>
      </mat-grid-tile>
      <mat-grid-tile *ngSwitchCase="'autocomplete-list'" [colspan]="field.size">
        <crm-form-autocomplete-list
          [formGroup]="formGroup"
          [field]="field"
        ></crm-form-autocomplete-list>
      </mat-grid-tile>
    </ng-container>
  </ng-container>
</form> -->
