import { Injectable } from '@angular/core';

import { ComponentCanDeactivate, DialogComponent } from '@fullyops/legacy/ui';
import { Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class CanDeactivateGuard {
  constructor(public dialog: MatDialog) {}

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    if (!component.canDeactivate()) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          message: 'leavePageMessage',
          closeModalText: 'cancel',
          actionModalText: 'leave',
        },
      });
      return dialogRef.afterClosed() as Observable<boolean>;
    }

    return of(true);
  }
}
