import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { SnackbarService } from '../ui-snackbar.service';
import { UiAuthService } from '../ui-auth.service';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipGrid } from '@angular/material/chips';

type EmailsList = 'bccList' | 'ccList';

export interface SelectEmailDialogData {
  showCcList: boolean;
  showBccList: boolean;
  ccList: string[];
  title: string;
}

export interface SelectEmailResponseDialogData {
  ccList: string[];
  bccList: string[];
}

@Component({
  selector: 'app-select-email-dialog',
  templateUrl: './select-email-dialog.component.html',
  styleUrls: ['./select-email-dialog.component.scss'],
})
export class SelectEmailDialogComponent implements OnInit {
  @ViewChild('ccInput') ccInput: ElementRef<HTMLInputElement>;
  @ViewChild('bccInput') bccInput: ElementRef<HTMLInputElement>;
  @ViewChild('ccEmailChipList') ccEmailChipList: MatChipGrid;
  @ViewChild('bccEmailChipList') bccEmailChipList: MatChipGrid;

  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  filteredCCContactsEmail: Observable<string[]>;
  ccList: string[] = [];
  ccEmailControl = new FormControl('');

  filteredBCCContactsEmail: Observable<string[]>;
  bccList: string[] = [];
  bccEmailControl = new FormControl('');

  userCanSendEmail = false;

  constructor(
    public dialogRef: MatDialogRef<SelectEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SelectEmailDialogData,
    private snackBarService: SnackbarService,
    public authService: UiAuthService
  ) {
    this.filteredCCContactsEmail = this.ccEmailControl.valueChanges.pipe(
      startWith(null),
      map((email: string | null) =>
        this._filter({
          value: email,
          originalList: this.data.ccList,
          formList: this.ccList,
        })
      )
    );
  }

  ngOnInit(): void {
    this.loadAllContactsEmail();

    this.authService
      .hasPermission([PermissionType.CAN_SEND_REQUEST_NOTIFICATION_EMAIL])
      .subscribe((res) => {
        this.userCanSendEmail = res;
      });
  }

  isValidEmail = (email) => {
    // Regular expression pattern for a basic email validation
    const emailPattern =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    // Test the email against the pattern
    if (!emailPattern.test(email)) {
      this.openSnackBarInvalidEmail();
      return false;
    }
    return true;
  };

  openSnackBarInvalidEmail() {
    this.snackBarService.openError({ message: 'please select a valid email' });
  }

  loadDefaultEmail() {}

  loadAllEmails() {}

  loadAllContactsEmail() {}

  setWarningState(companyEmail: string) {}

  onNoClick() {
    this.dialogRef.close();
  }

  sendEmail() {
    const { ccList, bccList } = this;
    this.dialogRef.close({ ccList, bccList });
  }

  remove(email, list: EmailsList) {
    const index = this[list].indexOf(email);

    if (index >= 0) {
      this[list].splice(index, 1);
    }

    this.ccEmailControl.updateValueAndValidity();
    this.bccEmailControl.updateValueAndValidity();
  }

  add(event: HTMLInputElement, list: EmailsList) {
    if (!event.value) return;

    if (this[list].includes(event.value)) {
      return this.snackBarService.openError({
        message: 'error:thisEmailHasAlreadyBeenAdded',
      });
    }

    if (event.value) {
      if (!this.isValidEmail(event.value)) {
        if (list == 'bccList') this.bccEmailControl.setErrors({ error: true });
        if (list == 'ccList') this.ccEmailControl.setErrors({ error: true });
        return;
      }
      this[list].push(event.value);
    }

    event.value = null;

    this.ccEmailControl.setValue(null);
    this.ccEmailControl.updateValueAndValidity();

    this.bccEmailControl.setValue(null);
    this.bccEmailControl.updateValueAndValidity();
  }

  selected(event: MatAutocompleteSelectedEvent, list: EmailsList) {
    if (!this.isValidEmail(event.option.viewValue)) return;
    this[list].push(event.option.viewValue);

    if (this.data.showCcList) {
      this.ccInput.nativeElement.value = '';
      this.ccEmailControl.setValue(null);
      this.ccEmailControl.updateValueAndValidity();
    }

    if (this.data.showBccList) {
      this.bccInput.nativeElement.value = '';
      this.bccEmailControl.setValue(null);
      this.bccEmailControl.updateValueAndValidity();
    }
  }

  private _filter(params: {
    value: string;
    originalList: string[];
    formList: string[];
  }): string[] {
    const { formList, originalList, value } = params;

    const notAddedEmails = originalList.filter(
      (email) => !formList.includes(email)
    );

    if (!value) return notAddedEmails;

    const filteredList = notAddedEmails.filter((email) =>
      email.startsWith(value)
    );

    return filteredList;
  }
}
