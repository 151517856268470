<crm-container
  [maxSize]="maxSize"
  [noPaddingTop]="true"
  [halfVerticalPadding]="true"
>
  <div class="breadcrumb-v2">
    <div class="breadcrumb-v2-left">
      <span class="mat-subtitle-2">{{ header | i18nextCap }}</span>
    </div>

    <div class="breadcrumb-v2-center">
      <mat-button-toggle-group
        *ngIf="tabs"
        name="tabs"
        [value]="tabs._tabs.first.textLabel"
      >
        <mat-button-toggle
          *ngFor="let tab of tabs._tabs; index as i"
          (click)="changeTab(i)"
          value="{{ tab.textLabel }}"
          >{{ tab.textLabel | i18nextCap }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <ng-container>
      <div class="breadcrumb-v2-right">
        <div class="breadcrumb-v2-special-buttons">
          <ng-container
            *ngIf="specialActionButtons && specialActionButtons.length > 0"
          >
            <button
              mat-icon-button
              aria-label="More options"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
              (click)="isMoreOptionsOverlayOpened = !isMoreOptionsOverlayOpened"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <!--<button
              *ngFor="let button of specialActionButtons"
              mat-flat-button
              [class]="button.color"
              type="button"
              (click)="fireAction(button.label)"
            >
              {{ button.label | i18nextCap }}
            </button>-->

            <!-- This template displays the overlay content and is connected to the button -->
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="isMoreOptionsOverlayOpened"
            >
              <div class="more-options-menu" cdkMenu>
                <button
                  *ngFor="let button of specialActionButtons"
                  cdkMenuItem
                  (click)="fireAction(button.label)"
                  class="more-options-menu-item {{ button.color }}"
                >
                  {{ button.label | i18nextCap }}
                </button>
              </div>
            </ng-template>
          </ng-container>

          <button
            mat-flat-button
            color="warn"
            type="button"
            [hideIfNotAllowed]="actionButtons?.warn.permissions"
            (click)="fireAction(actionButtons?.warn.label)"
            [attr.data-testid]="actionButtons?.warn.dataTestId"
            *ngIf="actionButtons?.warn"
          >
            {{ 'forms:' + actionButtons?.warn.label | i18nextCap }}
          </button>

          <button
            type="button"
            mat-flat-button
            color="primary"
            [hideIfNotAllowed]="actionButtons?.primary?.permissions"
            (click)="fireAction(actionButtons?.primary?.label)"
            [attr.data-testid]="actionButtons?.primary?.dataTestId"
            *ngIf="actionButtons?.primary"
          >
            {{ 'forms:' + actionButtons?.primary?.label | i18nextCap }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</crm-container>
