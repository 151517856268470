<ng-container *ngIf="pipeline$ | async as pipeline">
  <div
    *ngIf="statuses$ | async as statuses"
    class="pipeline-container"
    cdkDropListGroup
  >
    <div
      [ngClass]="['tickets-container', 'pipeline-statuses-' + statuses.length]"
      *ngFor="let status of $any(statuses); index as i"
    >
      <div class="tickets-container-colum">
        <div [ngClass]="['header', status.color]">
          <div>
            <img
              [attr.alt]="status.name"
              [src]="
                'assets/svg/statuses/' +
                status.name.toLocaleLowerCase() +
                '.svg'
              "
            />
            {{ 'forms:' + status.name | i18nextCap }}
          </div>
          <span>{{ status.count }}</span>
        </div>
        <div
          cdkDropList
          [id]="status.id"
          [cdkDropListData]="status.tickets"
          class="tickets-list"
          (cdkDropListDropped)="drop($any($event))"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="50"
          [scrollWindow]="false"
          (scrolled)="loadMoreTicketsEvent(status.id)"
        >
          <div
            *ngFor="let ticket of status.tickets; trackBy: trackByFn"
            [id]="ticket.id"
            (click)="onOpen(ticket)"
            cdkDrag
            [cdkDragData]="ticket"
            [cdkDragStartDelay]="150"
          >
            <crm-pipeline-ticket [ticket]="$any(ticket)"></crm-pipeline-ticket>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
