<section>
  <div class="filters">
    <div class="form-filters">
      <crm-form-select
        [formGroup]="filtersForm"
        [field]="$any(orderBy)"
        (selectionChange)="applyFilters()"
        class="padding-crm-form-template"
      ></crm-form-select>
      <crm-form-select
        [formGroup]="filtersForm"
        [field]="$any(order)"
        (selectionChange)="applyFilters()"
        class="padding-crm-form-template"
      ></crm-form-select>
    </div>
    <button
      mat-icon-button
      [matMenuTriggerFor]="matMenu"
      [attr.data-testid]="'filters-button'"
    >
      <mat-icon
        [matBadge]="activeFilters"
        [matBadgeHidden]="activeFilters === 0"
        matBadgePosition="before"
        matBadgeSize="small"
        matBadgeColor="primary"
        >tune</mat-icon
      >{{ 'translation:filters' | i18nextCap }}
    </button>
  </div>
  <mat-chip-listbox
    #chipList
    aria-label="filters"
    class="selected-filters"
    *ngIf="currentFilters$ | async as filters"
  >
    <mat-chip
      *ngFor="let filter of filters | keyvalue"
      [removable]="true"
      (removed)="removeFilter($any(filter).key)"
    >
      <b>{{ filter.key | i18nextCap }}: </b
      >{{ getText(filter.value) | i18nextCap }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-listbox>
</section>

<mat-menu #matMenu="matMenu" xPosition="before">
  <div class="filters-menu">
    <label>{{ 'translation:filters' | i18nextCap }}</label>
    <crm-form-template
      [formGroup]="filtersForm"
      [formFields]="filtersFormFields"
      (click)="$event.stopPropagation()"
      class="padding-crm-form-template"
    ></crm-form-template>
    <button
      mat-flat-button
      color="primary"
      (click)="applyFilters()"
      [attr.data-testid]="'apply-filters-button'"
    >
      <mat-icon>filter_alt</mat-icon>
      <span>{{ 'translation:applyFilters' | i18nextCap }}</span>
    </button>
  </div>
</mat-menu>
