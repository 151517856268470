import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { UiAuthService } from '@fullyops/legacy/ui/ui-crm/ui-auth.service';

/**
 * TODO: This service does more than formatting dates, should be renamed
 */
@Injectable({
  providedIn: 'root',
})
export class TimeZoneDateFormatterService {
  constructor(protected uiAuthService: UiAuthService) {
    moment.locale(this.uiAuthService.currentUser.locale); // TODO: This mutates the global language for moment. It will make testing harder in the future.
  }

  formatInTimezone(date: Date | string | number[]): moment.Moment {
    return moment(date).tz(this.uiAuthService.currentUser.timeZone);
  }

  currentDate() {
    return moment().tz(this.uiAuthService.currentUser.timeZone);
  }

  formatTimeString(dateString: Date | string | number[]) {
    const date = this.formatInTimezone(dateString).toDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const hourStr = hour >= 10 ? hour : `0${hour}`;
    const minStr = minutes >= 10 ? minutes : `0${minutes}`;
    return `${hourStr}:${minStr}`;
  }

  getTimeDiff(
    startingHour: Date | string | number[],
    endingHour: Date | string | number[]
  ) {
    const startDate = this.formatInTimezone(startingHour).toDate();
    startDate.setSeconds(0);
    const endDate = this.formatInTimezone(endingHour).toDate();
    endDate.setSeconds(0);
    return Math.abs(endDate.getTime() - startDate.getTime());
  }
}
