import { Component, OnInit } from '@angular/core';
import { WorkOrderScopesService } from '@fullyops/domain/work-order-scopes/services/work-order-scopes.service';
import { ActionButtons } from '@fullyops/legacy/ui/index';
import { map } from 'rxjs';

@Component({
  selector: 'app-work-order-scopes',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class WorkOrderScopesComponent implements OnInit {
  constructor(private service: WorkOrderScopesService) {}

  ngOnInit(): void {
    this.service.getAll();
  }

  actions: ActionButtons = {};

  protected scopes$ = this.service.scopes$;
  protected noScopesFound$ = this.scopes$.pipe(
    map((scopes) => scopes.length === 0)
  );

  onActionEvent(event: string) {}

  toggleActive(label: string) {
    this.service.toggleActive(label);
  }

  setColor(label: string, color: string) {
    this.service.setColor(label, color);
  }

  setDefault(label: string) {
    this.service.setDefault(label);
  }

  protected readonly tableHeaders = ['label', 'color', 'active', 'isDefault'];
}
