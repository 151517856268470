import { Injectable } from '@angular/core';
import { PasswordEndpoint, ResetPasswordPayload } from '@fullyops/legacy/data';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TenantResolverService } from '@fullyops/core/services/tenant/tenant-resolver.service';

@Injectable({
  providedIn: 'root',
})
export class UiPasswordService {
  constructor(
    protected passwordEndpoint: PasswordEndpoint,
    protected tenantResolver: TenantResolverService
  ) {}

  forgotPassword(username: string): Observable<{}> {
    const tenantName = window.location.host.split('.')[0].replace(':', '');
    return this.passwordEndpoint.forgotPassword(
      username,
      this.tenantResolver.getActiveTenant()
    );
  }

  resetPassword(resetPassword: ResetPasswordPayload): Observable<{}> {
    return this.passwordEndpoint.resetPassword(
      resetPassword,
      this.tenantResolver.getActiveTenant()
    );
  }
}
