<crm-breadcrumb
  [header]="entity ? entity.serialNumber : 'newEquipment'"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
  [maxSize]="'xl'"
></crm-breadcrumb>
<ng-container
  *ngIf="clientEquipmentFormService.form$ | async as clientEquipmentForm"
>
  <form
    #ngForm="ngForm"
    [@inOut]="'in'"
    [formGroup]="clientEquipmentForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="form-row">
      <div class="col-md-12">
        <crm-form-template
          [formGroup]="clientEquipmentForm"
          [formFields]="formFields"
        ></crm-form-template>
      </div>
    </div>
  </form>
</ng-container>
