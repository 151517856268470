import { SupportTicketResponse } from './../../../data/api/types/SupportTicket';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { WorkPeriodControllerV2 } from '../ui-work-period-controller-v2.service';
import { WorkPeriodResponse } from '../../../data/api/types/Work';
import { WorkPeriodAssigneeControllerV2 } from '../ui-work-period-assignee.controller-v2.service';
import { UiAuthService } from '../ui-auth.service';
import { UserResponse } from '../../../data/api/types/User';
import { TenantConfigurationController } from '../ui-tenant-configuration-v2-controller.service';
import { convertMiliSecondsToTimeString } from '../../ui-shared/utils/date-transformers';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';
import { SidenavPortalBridgeService } from '@fullyops/core/sidenav-form-portal-bridge.service';
import { User } from '@fullyops/legacy/data';
import {
  NEW_WORKING_PERIOD_TOKEN,
  NewWorkingPeriodFormComponent,
} from '@fullyops/legacy/ui/ui-crm/working-period/new-working-period-form/new-working-period-form.component';

type UserTotalWorkedData = {
  id: string;
  firstName: string;
  lastName: string;
  total: number;
};

type WorkHistory = { assigneesWork: UserTotalWorkedData[]; total: number };

@Component({
  selector: 'app-working-period',
  templateUrl: './working-period.component.html',
  styleUrls: ['./working-period.component.scss'],
})
export class WorkingPeriodComponent implements OnInit {
  @Input() usersAssignees$: BehaviorSubject<UserResponse[]>;
  @Input() ticket$: BehaviorSubject<SupportTicketResponse>;
  @Input() defaultWorkingPeriodDuration$ = new BehaviorSubject<number>(30);

  constructor(
    protected workPeriodControllerV2: WorkPeriodControllerV2,
    private workPeriodAssigneeControllerV2: WorkPeriodAssigneeControllerV2,
    protected authService: UiAuthService,
    public timezoneDate: TimeZoneDateFormatterService,
    private tenantConfigurationController: TenantConfigurationController,
    private sidenavPortalBridgeService: SidenavPortalBridgeService
  ) {}

  workHistory$ = new BehaviorSubject<WorkHistory>(null);

  async ngOnInit() {
    await this.setDefaultWorkingPeriodDuration();
    this.parseAssigneesWorkingHours();
  }

  parseAssignees(workingPeriod: WorkPeriodResponse): User[] {
    return workingPeriod.assignees.map(
      (assignee) => assignee.assignee as unknown as User
    );
  }

  parseAssigneesWorkingHours() {
    this.ticket$.subscribe((ticket) => {
      const workHistory: WorkHistory = ticket.workPeriods.reduce<WorkHistory>(
        (acc: WorkHistory, workPeriod) => {
          workPeriod.assignees.forEach((item) => {
            const assigneeWork = acc.assigneesWork.find(
              (a) => a.id === item.assignee.id
            );
            const workedTime = this.timezoneDate.getTimeDiff(
              workPeriod.startingHour,
              workPeriod.endingHour
            );

            if (!assigneeWork) {
              const assigneeData = {
                id: item.assignee.id,
                firstName: item.assignee.firstName,
                lastName: item.assignee.lastName,
                total: workedTime,
              };
              acc.assigneesWork.push(assigneeData);
            } else {
              assigneeWork.total += workedTime;
            }
            acc.total += workedTime;
          });

          return acc;
        },
        { assigneesWork: [], total: 0 }
      );

      this.workHistory$.next(workHistory);
    });
  }

  loadWorkPeriod() {
    this.workPeriodControllerV2
      .getAllWorkPeriod({
        queryParams: {
          supportTicketId: this.ticket$.value.id,
          orderBy: 'CREATED_AT',
        },
      })
      .subscribe((res) => {
        const newTicket = { ...this.ticket$.value };
        newTicket.workPeriods = res.results;
        this.ticket$.next(newTicket);
      });
  }

  async setDefaultWorkingPeriodDuration() {
    await this.tenantConfigurationController
      .getConfigurationsByLabel({
        label: 'SUPPORT_TICKET_DEFAULT_WORKING_PERIOD_DURATION',
      })
      .toPromise()
      .then((e) => {
        if (!!e.value) this.defaultWorkingPeriodDuration$.next(+e.value);
      });
  }

  handleDeleteWorkingPeriod(id: string) {
    this.workPeriodControllerV2.deleteById({ id: id }).subscribe(() => {
      const newTicket = { ...this.ticket$.value };
      newTicket.workPeriods = this.ticket$.value.workPeriods.filter(
        (workPeriod) => workPeriod.id !== id
      );
      this.ticket$.next(newTicket);
      this.sidenavPortalBridgeService.closePortal();
    });
  }

  handleCreateWorkingPeriod = (newWorkPeriod) => {
    this.workPeriodControllerV2
      .createWorkPeriod({
        newWorkPeriod: {
          startingHour: newWorkPeriod.startingHour,
          endingHour: newWorkPeriod.endingHour,
          supportTicketId: this.ticket$.value.id,
        },
      })
      .subscribe((workPeriodAdded) => {
        let quantityOfAssigneeRequest = (newWorkPeriod.assignees as string[])
          .length;

        (newWorkPeriod.assignees as string[]).map((assigneeId) => {
          this.workPeriodAssigneeControllerV2
            .assignUserToWorkPeriod({
              assigneeId: assigneeId,
              workPeriodId: workPeriodAdded.id,
            })
            .subscribe(
              () => {},
              () => {},
              () => {
                quantityOfAssigneeRequest--;
                if (quantityOfAssigneeRequest == 0) {
                  this.loadWorkPeriod();
                  this.sidenavPortalBridgeService.closePortal();
                }
              }
            );
        });
      });
  };

  formatTimeString(dateString: string) {
    return this.timezoneDate.formatTimeString(dateString);
  }

  parseToTimeString(milliseconds) {
    return convertMiliSecondsToTimeString(milliseconds);
  }

  openForm(workingPeriod?: WorkPeriodResponse) {
    this.sidenavPortalBridgeService.openPortal(
      NewWorkingPeriodFormComponent,
      NEW_WORKING_PERIOD_TOKEN,
      {
        workingPeriod,
        assignees$: this.usersAssignees$,
        defaultWorkingPeriodDuration$: this.defaultWorkingPeriodDuration$,
        onSave: this.handleCreateWorkingPeriod,
        onCancel: this.closeForm,
        onDelete: !!workingPeriod
          ? () => this.handleDeleteWorkingPeriod(workingPeriod.id)
          : null,
      }
    );
  }

  closeForm = () => {
    this.sidenavPortalBridgeService.closePortal();
  };
}
