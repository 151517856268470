import { DialogComponent, DialogData } from '@fullyops/legacy/ui';
import { Injectable } from '@angular/core';
import { Report } from '@fullyops/legacy/data/api/types/Report';
import {
  SupportTicketRequest,
  SupportTicketResponse,
} from '@fullyops/legacy/data/api/types/SupportTicket';
import { Status } from '@fullyops/legacy/data/models/v0/Status';
import { InterventionResponse } from '@fullyops/legacy/data/api/types/Intervention';
import { WorkPeriodResponse } from '@fullyops/legacy/data/api/types/Work';
import { TimeZoneDateFormatterService } from '../date-formatter.service';
import { TicketStatusResponse } from '@fullyops/legacy/data/api/types/RequestTicke';
import { MatDialog } from '@angular/material/dialog';

type ValidatorResponse = {
  [teste in keyof SupportTicketResponse | 'reports']?: { error: string };
};

/**
 * // TODO: We only have declared a single validator, where is the remaining logic for validating the remaining entities?
 * // TODO: This service feels like it's out of place or should not exist at all.
 */
@Injectable({
  providedIn: 'root',
})
export class TicketSupportValidator {
  constructor(
    public dialog: MatDialog,
    public timezoneDate: TimeZoneDateFormatterService
  ) {}
  supportTicketId = null;
  ticketTitle = '';

  // TODO: This contains UI logic that is not technically part of the validator
  openDialog = async (props: { title: string; message: string }) => {
    const dialogRef = this.dialog.open<DialogComponent, DialogData>(
      DialogComponent,
      {
        data: {
          title: 'translation:' + props.title,
          message: 'translation:' + props.message,
          closeModalText: 'translation:cancel',
          actionModalText: 'translation:continue',
          actionButtonClass: 'button-warning',
          redirectHref: `tickets/support/${this.supportTicketId}/edit`,
          redirectHrefText: `${this.ticketTitle}`,
          redirectMessage: 'translation:goToTicket',
        },
        maxWidth: '600px',
      }
    );
    return await dialogRef
      .afterClosed()
      .toPromise()
      .then((isContinueButtonClicked) => {
        if (!isContinueButtonClicked) {
          return false;
        }
        return true;
      });
  };

  getLastReport(reports: Report[]) {
    reports.sort((reportA, reportB) => {
      const reportACreatedAt = this.timezoneDate.formatInTimezone(
        reportA.createdAt
      );
      const reportBCreatedAt = this.timezoneDate.formatInTimezone(
        reportB.createdAt
      );
      return reportACreatedAt.valueOf() - reportBCreatedAt.valueOf();
    });
    return reports[reports.length - 1];
  }

  async validateBeforeUpdate(props: {
    previousSupportTicketData: SupportTicketResponse;
    newSupportTicketData: SupportTicketRequest;
    reports: Report[];
    statuses: TicketStatusResponse[];
    interventions: InterventionResponse[];
    workPeriods: WorkPeriodResponse[];
  }): Promise<ValidatorResponse> {
    const response: ValidatorResponse = {};
    const {
      previousSupportTicketData,
      newSupportTicketData,
      reports,
      statuses,
      interventions,
      workPeriods,
    } = props;

    this.supportTicketId = newSupportTicketData.id;
    this.ticketTitle = previousSupportTicketData.identifier;

    const selectedStatus: TicketStatusResponse = statuses.find(
      (status) => status.id == newSupportTicketData.statusId
    );

    const lastReport = this.getLastReport(reports);

    const previousDataIsNotDoneOrExecuted =
      previousSupportTicketData.status.name.toLowerCase() !== 'done' &&
      previousSupportTicketData.status.name.toLowerCase() !== 'executed';
    const newTicketStatusIsDoneOrExecuted =
      selectedStatus.name.toLowerCase() == 'done' ||
      selectedStatus.name.toLowerCase() == 'executed';

    if (previousDataIsNotDoneOrExecuted && newTicketStatusIsDoneOrExecuted) {
      const noReport = reports.length == 0;
      const noInterventions = interventions.length == 0;
      const noWorkPeriod = workPeriods.length == 0;
      const noReportAssigned = reports.every((report) => report.signed != true);
      const lastReportSigned = lastReport ? lastReport.signed : false;

      if (noInterventions && noWorkPeriod) {
        const dialogResponse = await this.openDialog({
          title: 'WOUpdateNoInterWorkPeriodTitle',
          message: 'WOUpdateNoInterWorkPeriodMessage',
        });
        if (!dialogResponse)
          return {
            interventions: { error: 'no intervention' },
            workPeriods: { error: 'no work period' },
          };
        return {};
      }

      if (noInterventions && !noWorkPeriod) {
        const dialogResponse = await this.openDialog({
          title: 'WOUpdateNoInterventionTitle',
          message: 'WOUpdateNoInterventionMessage',
        });
        if (!dialogResponse)
          return { interventions: { error: 'no intervention' } };
        return {};
      }

      if (noWorkPeriod && !noInterventions) {
        const dialogResponse = await this.openDialog({
          title: 'WOUpdateNoWorkPeriodTitle',
          message: 'WOUpdateNoWorkPeriodMessage',
        });
        if (!dialogResponse)
          return { workPeriods: { error: 'no work period' } };
        return {};
      }

      if (noReport) {
        const dialogResponse = await this.openDialog({
          title: 'WOUpdateNoReportTitle',
          message: 'WOUpdateNoReportMessage',
        });
        if (!dialogResponse) return { reports: { error: 'no report' } };
        return {};
      }

      if (noReportAssigned) {
        const dialogResponse = await this.openDialog({
          title: 'WOUpdateReportNotSignedTitle',
          message: 'WOUpdateReportNotSignedMessage',
        });
        if (!dialogResponse) return { reports: { error: 'no report signed' } };
        return {};
      }

      if (!lastReportSigned) {
        const dialogResponse = await this.openDialog({
          title: 'WOUpdateLastReportNotSignedTitle',
          message: 'WOUpdateLastReportNotSignedMessage',
        });
        if (!dialogResponse)
          return { reports: { error: 'last report not signed' } };
        return {};
      }
    }

    return {};
  }
}
