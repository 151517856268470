<mat-expansion-panel *ngIf="contact">
  <mat-expansion-panel-header
    [attr.data-testid]="'create-contact-accordion-header'"
  >
    <mat-panel-title class="contact-title">
      <div class="user-icon blue-icon">
        <fa-icon [icon]="faUser" size="xl"></fa-icon>
      </div>
      <!-- <mat-icon class="blue-icon">add_circle</mat-icon> -->
      {{ contact.name }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="">
    <crm-form-template
      [formGroup]="contactForm"
      [formFields]="contactFields"
    ></crm-form-template>
    <div class="col-md-12">
      <div class="buttons-right">
        <app-button-v2
          text="delete"
          color="red"
          (onClick)="deleteContact()"
        ></app-button-v2>
        <app-button-v2
          text="save"
          color="blue"
          (onClick)="editContact()"
        ></app-button-v2>
      </div>
    </div>
  </div>
</mat-expansion-panel>
