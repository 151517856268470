<crm-breadcrumb
  [header]="title"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
  [maxSize]="'xl'"
></crm-breadcrumb>
<ng-container *ngIf="lossMotives$ | async as lossMotives">
  <ng-container [ngSwitch]="lossMotives.state">
    <crm-error *ngSwitchCase="state.Error" [@inOut]="'in'"> </crm-error>
    <ng-container *ngSwitchCase="state.Loaded">
      <div [@inOut]="'in'">
        <crm-list-container
          [list$]="lossMotivesList$"
          (openEvent)="onOpen($event)"
        >
        </crm-list-container>
        <ng-container *ngIf="lossMotivesList$ | async as list">
          <crm-empty-warn
            *ngIf="!list.length"
            [message]="'noLossMotives'"
            [actionLabel]="'addNewLossMotive'"
            (actionEvent)="onAddNew()"
          ></crm-empty-warn>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
