import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  UnsavedProduct,
  SubProduct,
  UnsavedSubProduct,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { SubProductFormService } from './sub-product-form.service';
import {
  ProductStatus,
  ActionButtons,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { UntypedFormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';

@Component({
  selector: 'crm-sub-product-form',
  templateUrl: './sub-product-form.component.html',
  styleUrls: ['./sub-product-form.component.scss'],
  providers: [SubProductFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class SubProductFormComponent implements OnInit {
  @Input() preview = false;
  @Input() productId = '';
  @Input() subProduct?: SubProduct;
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter<UnsavedSubProduct>();
  @Output() addVariantEvent = new EventEmitter<{
    variantId: string;
    unsavedSubProduct: UnsavedSubProduct;
  }>();
  @Output() editVariantEvent = new EventEmitter<{
    variantId: string;
    unsavedSubProduct: UnsavedSubProduct;
  }>();

  draft$: BehaviorSubject<UnsavedSubProduct>;
  actions: ActionButtons = {};
  form: UntypedFormGroup;
  formFields: any[];

  statusEnum = ProductStatus;

  constructor(
    public subProductFormService: SubProductFormService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.draft$ = new BehaviorSubject<UnsavedSubProduct>(
      new UnsavedSubProduct('', [], this.productId)
    );

    this.initForm();
    this.initActionButtons();
    this.initFields();
  }

  initFields() {
    this.formFields = [
      [
        { type: 'separator', label: 'subProductInformation' },
        { name: 'name', label: 'subProductName', type: 'text', size: 6 },
      ],
    ];
  }

  initActionButtons() {
    if (this.preview) {
      this.actions.primary = {
        label: 'edit',
        color: 'primary',
        permissions: [PermissionType.CAN_EDIT_PRODUCTS],
      };
    } else {
      this.actions.primary = {
        label: 'save',
        color: 'primary',
      };
    }

    if (!this.preview && this.subProduct) {
      this.actions.warn = {
        label: 'delete',
        color: 'warn',
      };
    }
  }

  initForm() {
    if (this.subProduct) {
      this.draft$.next(this.subProduct.toDraft());
    }

    this.subProductFormService.initForm(this.draft$.getValue(), this.preview);
    this.subProductFormService.subProductForm$.subscribe(
      (form) => (this.form = form)
    );
  }

  addVariant() {
    if (this.form.valid) {
      this.addVariantEvent.emit({
        variantId: null,
        unsavedSubProduct: this.subProductFormService.getSubProductDraft(),
      });
    } else {
      this.subProductFormService.markAllAsTouched();
      this.openSnackBar('Some mandatory fields are still blank!', 'Close');
    }
  }

  editVariant(variantId: string) {
    if (this.form.valid) {
      this.editVariantEvent.emit({
        variantId,
        unsavedSubProduct: this.subProductFormService.getSubProductDraft(),
      });
    } else {
      this.subProductFormService.markAllAsTouched();
      this.openSnackBar('Some mandatory fields are still blank!', 'Close');
    }
  }

  onActionEvent(event: string) {
    switch (event) {
      case 'save':
        this.onSubmit();
        break;
      case 'cancel':
        this.onCancel();
        break;
      case 'delete':
        this.onDelete();
        break;
      case 'edit':
        this.onEdit();
        break;
      default:
        break;
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.saveEvent.emit(this.subProductFormService.getSubProductDraft());
    } else {
      this.subProductFormService.markAllAsTouched();
      this.openSnackBar('Some mandatory fields are still blank!', 'Close');
    }
  }

  onCancel() {
    this.cancelEvent.emit();
  }

  onEdit() {
    this.editEvent.emit();
  }

  onDelete() {
    this.deleteEvent.emit();
  }
}
