import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UiPasswordService } from '@fullyops/legacy/ui';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup;
  loading$ = new BehaviorSubject(false);
  submitted = false;
  formSent = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private passwordService: UiPasswordService
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  onSubmit() {
    this.submitted = true;
    var userFound: Boolean = false; //sets to true if user is found

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      if (this.forgotPasswordForm.controls.username.errors.required) return;
      else this.forgotPasswordForm.controls.username.setErrors(null); //clear all errors
    }

    this.loading$.next(true);
    this.passwordService
      .forgotPassword(this.forgotPasswordForm.controls.username.value)
      .subscribe(
        (res) => {
          userFound = true;
          this.formSent = true;
        },
        (err) => {},
        () => {
          //onCompleted
          if (!userFound) {
            //let the app know that user wasn't found
            this.forgotPasswordForm.controls.username.setErrors({
              userNotFound: true,
            });
          }
          this.loading$.next(false);
        }
      );
  }
}
