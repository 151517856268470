<ng-container *ngIf="companyFormService.form$ | async as companyForm">
  <crm-breadcrumb
    [header]="entity ? entity.name : 'newCompany'"
    [actionButtons]="actions"
    (actionEvent)="onActionEventV2($event, companyForm)"
    [maxSize]="'xl'"
  ></crm-breadcrumb>
  <crm-container [maxSize]="'xl'" [removeNegativeMargin]="true">
    <div class="row mx-0">
      <!-- Left -->
      <div [ngClass]="entity ? 'col-md-8' : 'col-md-12'">
        <mat-tab-group
          [selectedIndex]="selectedTabIndex.value"
          (selectedIndexChange)="selectedTabIndex.setValue($event)"
          (selectedTabChange)="onTabChange()"
        >
          <!-- Details -->
          <mat-tab>
            <ng-template mat-tab-label>{{
              'forms:company' | i18nextCap
            }}</ng-template>
            <div class="add-padding">
              <crm-form-template
                [formGroup]="companyForm"
                [formFields]="formFields"
              ></crm-form-template>
              <button
                type="button"
                [disabled]="companyButtonDisabled"
                mat-flat-button
                color="primary"
                (click)="onSave()"
                [disabled]="companyForm.invalid"
              >
                {{ 'forms:' + 'save' | i18nextCap }}
              </button>
            </div>
          </mat-tab>

          <!-- Contacts -->
          <mat-tab *ngIf="entity">
            <ng-template mat-tab-label>{{
              'forms:contacts' | i18nextCap
            }}</ng-template>

            <div class="tab-content-padding">
              <mat-accordion
                ><mat-expansion-panel>
                  <mat-expansion-panel-header
                    [attr.data-testid]="'create-contact-accordion-header'"
                  >
                    <mat-panel-title class="contact-title">
                      <mat-icon class="green-icon">add_circle</mat-icon>
                      {{ 'translation:newContact' | i18nextCap }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="">
                    <crm-form-template
                      [formGroup]="newContactForm"
                      [formFields]="newContactFields"
                    ></crm-form-template>
                    <div class="col-md-12">
                      <div class="buttons-right">
                        <app-button-v2
                          text="create"
                          (onClick)="createContact()"
                          color="green"
                        ></app-button-v2>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>

                <ng-container *ngIf="allContacts$ | async as contacts">
                  <crm-company-contact-expansion-panel
                    *ngFor="let contact of contacts; let i = index"
                    [contact]="contact"
                    [index]="i"
                    (editContactEvent)="editContact($event)"
                    (deleteContactEvent)="deleteContact($event)"
                  ></crm-company-contact-expansion-panel>
                </ng-container>
              </mat-accordion>
            </div>
          </mat-tab>

          <!-- Equipments -->
          <mat-tab
            [disabled]="!(canViewClientEquipment$ | async)"
            *ngIf="entity"
          >
            <ng-template mat-tab-label>
              <span
                [matTooltip]="
                  (canViewClientEquipment$ | async)
                    ? null
                    : ('youDoNotHavePermissionToAccessThisTab' | i18nextCap)
                "
              >
                {{ 'forms:equipments' | i18nextCap }}
              </span></ng-template
            >
            <div class="tab-content-padding">
              <mat-accordion>
                <crm-company-client-equipment-accordion
                  [isNew]="true"
                  (createEquipmentEmitter)="createEquipment($event)"
                  [indexExpanded]="indexExpanded"
                  [currentIndex]="0"
                  (setIndexExpanded)="setClientEquipmentIndexOpened(0)"
                ></crm-company-client-equipment-accordion>
                <ng-container *ngIf="equipments$ | async as equipments">
                  <crm-company-client-equipment-accordion
                    *ngFor="let equipment of equipments; let i = index"
                    [equipment]="equipment"
                    (editEquipmentEmitter)="editEquipment($event, i)"
                    (deleteEquipmentEmitter)="deleteEquipment(i)"
                    [indexExpanded]="indexExpanded"
                    [currentIndex]="i + 1"
                    (setIndexExpanded)="setClientEquipmentIndexOpened(i + 1)"
                  ></crm-company-client-equipment-accordion>
                </ng-container>
              </mat-accordion>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <!-- Right -->
      <div class="right-col col-md-4" *ngIf="entity">
        <div class="d-flex flex-column right-column-gap">
          <crm-history
            *ngIf="history$"
            [history$]="$any(history$)"
            (openActivityEvent)="onOpenActivity($event)"
            (openTicketEvent)="onOpenTicket($any($event))"
          ></crm-history>
        </div>
      </div>
    </div>
  </crm-container>
</ng-container>
