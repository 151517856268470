import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import {
  faCircleInfo,
  faCloudArrowUp,
  faCoffee,
  faPen,
  fas,
  faTrash,
  faTrashCan,
  faTriangleExclamation,
  IconName,
} from '@fortawesome/free-solid-svg-icons';
import { FormGroup, UntypedFormControl } from '@angular/forms';
// import { ImageController } from '../ui-image-v2-controller.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TenantConfigurationResponse } from '@fullyops/legacy/data/api/types/Tenant';
import { FormTemplateFields } from '../form-template/form-template.component';
import { DefaultDialogComponent } from '../default-dialog/default-dialog.component';
import { DialogService } from '../dialog/dialog.service';
import { ImageController } from '@fullyops/legacy/ui/ui-crm/ui-image-v2-controller.service';
import { SignedImageResponse } from '@fullyops/legacy/data/api/types/Image';
import defaultExport, { Options } from 'browser-image-compression';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'crm-input-images',
  templateUrl: './input-images.component.html',
  styleUrls: ['./input-images.component.scss'],
})
export class InputImages implements OnInit {
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    // public imageController: ImageController,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public dialogService: DialogService,
    private imageController: ImageController,
    public library: FaIconLibrary
  ) {
    library.addIconPacks(fas);
  }
  @Input() formGroup: FormGroup;
  @Input() formControlElement: UntypedFormControl;
  @Input() description: string;
  @Input() hasError: boolean;
  @Input() errorMessage: string;
  @Input() height: number = 100;
  @Input() label: string;
  @Input() configuration: TenantConfigurationResponse;

  @Input() field: FormTemplateFields<FormGroup<any>>;

  @Output() uploadTemplateEmitter = new EventEmitter();

  faCloudArrowUp = faCloudArrowUp;
  faCircleInfo = faCircleInfo;
  faTriangleExclamation = faTriangleExclamation;
  faTrash = faTrash;
  faTrashCan = faTrashCan;
  faPen = faPen;

  isDragOver = false;

  accept: string = '';

  @HostListener('dragover', ['$event'])
  onDragOver($event: DragEvent) {
    if (!(($event.target as HTMLInputElement).id == 'addImage')) return;
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.isDragOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop() {
    this.isDragOver = false;
  }

  ngOnInit() {}

  isFileFormatValid(file: File) {
    if (!this.field?.inputImages?.accept) return true;
    const acceptable = this.field?.inputImages?.accept.split(',');
    const acceptableTypes = acceptable.filter((e) => e.includes('/'));
    const acceptableExtension = acceptable.filter((e) => e.includes('.'));

    const checkType = () => {
      const acceptAnyOfType = acceptableTypes
        .filter((e) => e.includes('*'))[0]
        ?.split('/')[0];

      if (acceptAnyOfType) return file.type.includes(acceptAnyOfType);

      return acceptableTypes.includes(file.type);
    };

    const checkExtension = () => {
      const newFileExtension = '.' + file.name.split('.').pop();
      return acceptableExtension.includes(newFileExtension);
    };

    const typeIsValid = checkType();
    const extensionIsValid = checkExtension();

    if (!typeIsValid && !extensionIsValid) {
      this.dialogService.openDialog({
        title: 'invalidFormat',
        closeModalText: 'close',
        message: 'uploadedFileFormatInvalidAcceptedFormatsAre',
        translationData: { accepted: 'image/png'.replace('/', '-') },
      });
    }

    return typeIsValid || extensionIsValid;
  }

  isFileSizeWithinLimit(file: File, maxSizeInKB: number) {
    const sizeInByte = maxSizeInKB * 1024;
    if (file.size >= sizeInByte) {
      this.dialogService.openDialog({
        title: 'fileTooHeavy',
        closeModalText: 'close',
        message: 'pleaseSelectFileLessThan',
        translationData: { maxKiloByte: maxSizeInKB },
      });
      return false;
    }
    return true;
  }

  addImageOnForm({
    imageUrl,
    typeToAddOnForm,
    file,
    inputElement,
  }: {
    imageUrl?: string | SafeUrl;
    typeToAddOnForm: 'url' | 'file';
    file?: File | SignedImageResponse;
    inputElement: HTMLInputElement;
  }) {
    if (!this.field.inputImages.multipleImage) {
      this.formGroup.controls[this.field.name].setValue(
        typeToAddOnForm == 'url' ? imageUrl : file
      );
    }

    if (this.field.inputImages.multipleImage) {
      const oldValue =
        (this.formGroup.controls[this.field.name].value as Array<any>) || [];

      this.formGroup.controls[this.field.name].setValue([
        typeToAddOnForm == 'url' ? imageUrl : file,
        ...oldValue,
      ]);
    }
    inputElement.value = null;
    this.formGroup.controls[this.field.name].markAsDirty();
    this.changeDetectorRef.detectChanges();
  }

  async addImage(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let file = null;

    if (this.field.inputImages.compressImage) {
      file = await this.resizeAndCompressImage(
        inputElement.files[0],
        140,
        140,
        1
      );
    } else {
      file = inputElement.files[0];
    }

    if (!this.isFileFormatValid(file)) {
      inputElement.value = null;
      return;
    }

    if (this.field.inputImages.maxSizeInKB) {
      if (
        !this.isFileSizeWithinLimit(file, this.field.inputImages.maxSizeInKB)
      ) {
        inputElement.value = null;
        return;
      }
    }

    if (!file) {
      inputElement.value = null;
      return;
    }

    if (this.field?.inputImages?.uploadImageOnAdd) {
      const formData = new FormData();
      formData.append('file', file, `${file.name}`);
      this.imageController.upload({ file }).subscribe(({ id }) => {
        this.imageController.getById({ id }).subscribe((res2) => {
          this.addImageOnForm({
            inputElement,
            typeToAddOnForm: 'file',
            file: res2,
          });
        });
      });
    } else {
      const imageUrl = this.sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(file)
      );
      this.addImageOnForm({
        imageUrl,
        inputElement,
        typeToAddOnForm: 'file',
        file,
      });
    }
  }

  removeImage(index: number) {
    const dialogRef = this.dialogService.openDialogBeforeDelete();
    dialogRef.afterClosed().subscribe((saveOutput) => {
      if (saveOutput) {
        const newValue =
          (this.formGroup.controls[this.field.name].value as Array<any>) || [];
        newValue.splice(index, 1);
        this.formGroup.controls[this.field.name].setValue(newValue);
        this.formGroup.markAsDirty();
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  changeImage(event: Event, index: number) {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files[0];
    if (!file) return;
    const newValue = this.formGroup.controls[this.field.name]
      .value as Array<any>;

    const imageUrl = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(file)
    );
    newValue[index] = imageUrl;

    this.formGroup.controls[this.field.name].setValue(newValue);
  }

  onImageClick(image: SignedImageResponse) {
    this.dialog.open(DefaultDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '96%',
      width: '96%',
      data: {
        imageUrl: this.getImageUrl(image),
        file: image,
        title: this.formatFileName(image.file.fileName),
      },
    });
  }

  getImageUrl(value) {
    if (typeof value == 'string') {
      return value;
    }

    if (this.field.inputImages.isSignedImageResponseType) {
      return (value as SignedImageResponse)?.file?.signedUrl;
    }

    if (value instanceof File) {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(value));
    }

    return value;
  }

  async onFileSelected(file: File): Promise<File | null> {
    if (!file) {
      return null;
    }

    const img = new Image();
    const maxWidth = 100;
    const maxHeight = 100;

    return new Promise((resolve, reject) => {
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let newWidth = img.width;
        let newHeight = img.height;

        if (img.width > maxWidth || img.height > maxHeight) {
          const widthRatio = img.width / maxWidth;
          const heightRatio = img.height / maxHeight;

          if (widthRatio > heightRatio) {
            newWidth = maxWidth;
            newHeight = Math.round(img.height / widthRatio);
          } else {
            newHeight = maxHeight;
            newWidth = Math.round(img.width / heightRatio);
          }
        }

        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        canvas.toBlob((blob) => {
          // Create a new object File with blob redementioned
          const compressedFile = new File([blob], file.name, {
            type: file.type,
          });
          resolve(compressedFile);
        }, file.type);
      };

      img.src = URL.createObjectURL(file);
    });
  }

  async resizeAndCompressImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    quality: number
  ): Promise<File | null> {
    try {
      const options: Options = {
        maxSizeMB: quality, // quality of compressions in MB
        maxWidthOrHeight: Math.max(maxWidth, maxHeight),
      };

      const compressedBlob = await defaultExport(file, options);

      // Create a new object File with the base Blob redemetioned
      const compressedFile = new File([compressedBlob], file.name, {
        type: file.type,
      });

      return compressedFile;
    } catch (error) {
      return null; // Return null in case error
    }
  }

  formatFileSize(bytes) {
    if (bytes < 1024) {
      return bytes + ' bytes';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
  }

  formatFileName(fileName: string) {
    return fileName.split('.')[0];
  }

  getFileTypeName(file: SignedImageResponse): IconName {
    const fileName = file.file.fileName.toLowerCase();
    const imageExtensions = [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'tif',
      'tiff',
      'svg',
      'webp',
      'raw',
      'cr2',
      'nef',
    ];

    const isImage = imageExtensions.some((ext) => fileName.endsWith(ext));
    if (isImage) return 'image';
    if (fileName.endsWith('pdf')) return 'file-pdf';
    if (fileName.endsWith('docx')) return 'file-word';
    if (fileName.endsWith('csv')) return 'file-csv';
    return 'file';
  }
}
