import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  title: string;
  closeModalText: string;
  actionModalText?: string;
  actionButtonClass?: string;
  closeButtonClass?: string;
  tokenId?: string;
  noTranslation?: string;
  translationData?: any;
  redirectMessage?: string;
  redirectHref?: string;
  redirectHrefText?: string;
}

@Component({
  selector: 'crm-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  faClipboard = faClipboard;
  hidePassword = true;

  onCancel(): boolean {
    this.dialogRef.close();
    return false;
  }
  onLeave(): boolean {
    this.dialogRef.close();
    return true;
  }
}
