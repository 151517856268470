export const convertMiliSecondsToTimeString = (milliseconds) => {
  let minutes = Math.round((milliseconds / (1000 * 60)) % 60);
  let hours = Math.floor(milliseconds / (1000 * 60 * 60));
  if (minutes == 60) {
    minutes = 0;
    hours += 1;
  }

  const hoursString = hours > 0 ? `${hours}h` : '';

  const minutesString = minutes > 0 ? `${minutes} min` : '';

  return `${hoursString} ${minutesString}`;
};

export const getFormattedDate = (props: {
  minutesShift?: number;
  date: moment.Moment;
}) => {
  const { date, minutesShift } = props;
  const momentDate = date.clone();
  const roundedMinutes = Math.ceil(momentDate.minutes() / 15) * 15;

  momentDate.minutes(roundedMinutes + (minutesShift ? minutesShift : 0));
  return momentDate.toDate();
};
