import { Component, Input, OnInit } from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { LossMotiveFormService } from './loss-motive-form.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LossMotive, UnsavedLossMotive } from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import { SnackbarService } from '../ui-snackbar.service';
import { UiAuthService } from '../ui-auth.service';

@Component({
  selector: 'crm-loss-motive-form',
  templateUrl: './loss-motive-form.component.html',
  styleUrls: ['./loss-motive-form.component.scss'],
  providers: [LossMotiveFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class LossMotiveFormComponent
  extends FormComponent<LossMotive, UnsavedLossMotive, LossMotiveFormService>
  implements OnInit
{
  constructor(
    public lossMotiveFormService: LossMotiveFormService,
    public snackBar: SnackbarService,
    public authService: UiAuthService
  ) {
    super(snackBar, authService);

    this.formService = lossMotiveFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_LOSS_MOTIVES];
    this.draft$ = new BehaviorSubject<UnsavedLossMotive>(
      new UnsavedLossMotive('', '')
    );
  }

  ngOnInit() {
    this.initForm();
    this.initFields();
  }

  initFields() {
    this.formFields = [
      { name: 'name', label: 'name', type: 'text', size: 12 },
      { name: 'description', label: 'description', type: 'text', size: 12 },
    ];
  }
}
