import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { I18NextPipe } from 'angular-i18next';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { FormTemplateFields } from '../form-template/form-template.component';
import { SelectOptionType } from '../form-select/form-select.component';
import { MatChipInputEvent } from '@angular/material/chips';

interface Item {
  id: string;
  name: string;
  color: string;
  icon?: string;
}

@Component({
  selector: 'crm-form-chips-autocomplete',
  templateUrl: './form-chips-autocomplete.component.html',
  styleUrls: ['./form-chips-autocomplete.component.scss'],
})
export class ChipsAutocompleteComponent implements OnInit {
  @Input() field: FormTemplateFields<FormGroup<any>>;
  @Input() formGroup: UntypedFormGroup;
  @ViewChild('chipsInput') chipsInput: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER];
  chipsAutocompleteCtrl = new UntypedFormControl('');

  filteredOptions: Observable<any[]>;

  constructor(
    private i18nextPipe: I18NextPipe,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setOptions();
    this.field.items$.subscribe(() => {
      this.setOptions();
    });

    this.formGroup.controls[this.field.name].valueChanges.subscribe(() => {
      this.setOptions();
    });

    this.applyOnChange();
  }

  capitalizeFirstLetterEachWord(str: string) {
    const arr = str.toLowerCase().split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  }

  getCompanyName(id) {
    const company: any = this.field.items$.value.find(
      (item: any) => item.id == id
    );
    return company
      ? this.capitalizeFirstLetterEachWord(company.name as string)
      : '?';
  }

  isNewValueAlreadyAdded(valueId: string) {
    if (!this.field?.autocomplete?.saveFullOptionValue) {
      const oldValue = (
        this.formGroup.get(this.field.name) as UntypedFormControl
      ).value;
      return oldValue ? oldValue.includes(valueId) : false;
    }
  }

  setOptions() {
    this.filteredOptions = this.chipsAutocompleteCtrl.valueChanges.pipe(
      startWith(''),
      map((valueId) => this._filter(valueId || ''))
    );
    this.cdRef.detectChanges();
  }

  add(event: MatChipInputEvent): void {
    if (this.field.autocomplete?.onTypeEnter?.callback) {
      this.field.autocomplete?.onTypeEnter?.callback({
        inputElement: event.input,
        chipsAutocompleteCtrl: this.chipsAutocompleteCtrl,
      });
    }
  }

  remove(value: any): void {
    const formControl = this.formGroup.controls[this.field.name];
    const indexToRemove = formControl.value.indexOf(value);
    if (indexToRemove >= 0) {
      const newValue = [...formControl.value];
      newValue.splice(indexToRemove, 1);
      this.formGroup.controls[this.field.name].setValue(newValue);
    }
    this.setOptions();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let value;
    if (this.field.autocomplete?.saveFullOptionValue) {
      value = event.option.value;
    } else {
      value = event.option.value.id;
    }
    if (this.isNewValueAlreadyAdded(value)) return;

    const originalFormControl = this.formGroup.get(
      this.field.name
    ) as UntypedFormControl;
    const oldFormControlValue: any[] = originalFormControl.value || [];
    const updatedFormControlValue = [...oldFormControlValue, value];

    (this.formGroup.get(this.field.name) as UntypedFormControl).patchValue(
      updatedFormControlValue
    );
    this.chipsAutocompleteCtrl.setValue('');
    this.chipsInput.nativeElement.value = '';
  }

  private _filter(value: string | { name: string }): SelectOptionType[] {
    const filterValue =
      typeof value === 'object'
        ? value.name.toLowerCase()
        : value.toLowerCase();

    const allOptions: SelectOptionType[] = this.field.items$.value;
    const selectedOptions: SelectOptionType[] =
      (this.formGroup.get(this.field.name) as UntypedFormControl).value || [];

    return allOptions.filter((option) => {
      const isOptionSelected = selectedOptions.some(
        ({ id }) => id == option.id
      );
      const matchesFilter = this.i18nextPipe
        .transform(option.name)
        .toLowerCase()
        .includes(filterValue);
      return !isOptionSelected && matchesFilter;
    });
  }

  applyOnChange() {
    if (!this.field.autocomplete?.onInputChange?.callback) return;
    const debounceTimeValue =
      this.field.autocomplete?.onInputChange?.debounceTime;

    this.chipsAutocompleteCtrl.valueChanges
      .pipe(debounceTimeValue && debounceTime(debounceTimeValue))
      .subscribe((e) => {
        this.field.autocomplete?.onInputChange?.callback(e);
      });
  }

  displayFn(item) {
    if (this.field?.autocomplete?.chipsDisplayWith) {
      return this.field.autocomplete?.chipsDisplayWith(item);
    } else return this.getCompanyName(item);
  }
}
