import { Component, Inject } from '@angular/core';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { LocaleService } from '@fullyops/core/services/locale/locale.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface DialogData {
  document: any;
  filename: string;
}

@Component({
  selector: 'crm-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogDocumentViewComponent {
  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<DialogDocumentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.lang = localeService.getCurrentLanguage();
  }

  faCircleXmark = faCircleXmark;

  lang: string;

  onCancel(): boolean {
    this.dialogRef.close();
    return false;
  }
  onLeave(): boolean {
    this.dialogRef.close();
    return true;
  }
}
