<crm-breadcrumb
  [header]="entity ? entity.name : 'newLossMotive'"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
  [maxSize]="'xl'"
></crm-breadcrumb>

<ng-container *ngIf="lossMotiveFormService.form$ | async as lossMotiveForm">
  <crm-container [maxSize]="'xl'">
    <form
      #ngForm="ngForm"
      [@inOut]="'in'"
      [formGroup]="lossMotiveForm"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-row">
        <div class="col-md-12">
          <crm-form-template
            [formGroup]="lossMotiveForm"
            [formFields]="formFields"
          ></crm-form-template>
          <button
            type="submit"
            mat-flat-button
            color="primary"
            [disabled]="preview"
          >
            {{ 'forms:save' | i18nextCap }}
          </button>
        </div>
      </div>
      <footer></footer>
    </form>
  </crm-container>
</ng-container>
