import { I18NextPipe } from 'angular-i18next';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HotToastService } from '@ngneat/hot-toast';
import { COLORS } from '@fullyops/legacy/ui/ui-shared/utils/styles';
import { MatSnackBarConfig } from '@angular/material/snack-bar';

const ENDPOINTS_AVOID_SNACKBAR = {
  POST: ['/api/password/reset/token/validate'],
};

@Injectable()
export class NotifyInterceptor implements HttpInterceptor {
  snackBarConfig: MatSnackBarConfig = {
    duration: 2000,
    horizontalPosition: 'end',
    verticalPosition: 'bottom',
  };

  constructor(
    private i18nextPipe: I18NextPipe,
    private snackBarService: HotToastService
  ) {}

  displaySuccess(message: string) {
    this.snackBarService.success(message, {
      position: 'bottom-right',
      duration: 5000,
      dismissible: true,
      style: {
        color: 'white',
        backgroundColor: COLORS.green,
      },
      icon: '✅',
    });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      (!req.method.includes('POST') &&
        !req.method.includes('PATCH') &&
        !req.method.includes('DELETE')) ||
      req.url.includes('api/authenticate') ||
      req.url.includes('api/users/me')
    ) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      tap((data) => {
        if (data instanceof HttpResponse && data.status === 200) {
          if (req.method.includes('POST')) {
            const url = new URL(req.url);
            if (ENDPOINTS_AVOID_SNACKBAR.POST.includes(url.pathname)) return;
            this.displaySuccess(
              this.i18nextPipe.transform('translation:successfullyCreated')
            );
          }

          if (req.method.includes('PATCH')) {
            this.displaySuccess(
              this.i18nextPipe.transform('translation:successfullyUpdated')
            );
          }

          if (req.method.includes('DELETE')) {
            this.displaySuccess(
              this.i18nextPipe.transform('translation:successfullyDeleted')
            );
          }
        }
      })
    );
  }
}
