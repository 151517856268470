import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';
import { UiAuthService } from '@fullyops/legacy/ui';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  passwordHidden = true;
  loginForm: UntypedFormGroup;
  loading$ = new BehaviorSubject(false);
  returnUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: UiAuthService,
    private snackBar: MatSnackBar
  ) {
    this.authService.authorizedObs.subscribe((authorized) => {
      // redirect to home if already logged in
      if (authorized) {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return this.openSnackBar('Fields not valid', 'Please try again');
    }

    this.loading$.next(true);
    this.authService
      .login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe((data: HttpErrorResponse) => {
        if (data.error) {
          this.loading$.next(false);
        }
        this.router.navigate([this.returnUrl]);
      });
  }
}
