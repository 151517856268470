import { Component, Input, OnInit } from '@angular/core';
import {
  Product,
  ProductConfig,
  UnsavedProductConfig,
} from '@fullyops/legacy/data';
import { BehaviorSubject } from 'rxjs';
import { ProductConfigFormService } from './product-configuration-form.service';
import {
  ProductStatus,
  PermissionType,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import { SnackbarService } from '../ui-snackbar.service';
import { UiAuthService } from '../ui-auth.service';

@Component({
  selector: 'crm-product-configuration-form',
  templateUrl: './product-configuration-form.component.html',
  styleUrls: ['./product-configuration-form.component.scss'],
  providers: [ProductConfigFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class ProductConfigurationFormComponent
  extends FormComponent<
    ProductConfig,
    UnsavedProductConfig,
    ProductConfigFormService
  >
  implements OnInit
{
  @Input() uploadService: (
    file: File,
    extension: string
  ) => BehaviorSubject<{ url: string }>;
  @Input() products$: BehaviorSubject<Product[]>;
  @Input() simulation = false;

  header: string;
  productFields: any[];
  productImage: string;

  statusEnum = ProductStatus;

  constructor(
    public productConfigFormService: ProductConfigFormService,
    public snackBar: SnackbarService,
    public authService: UiAuthService
  ) {
    super(snackBar, authService);

    this.formService = productConfigFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_PRODUCTS];
    this.draft$ = new BehaviorSubject<UnsavedProductConfig>(
      new UnsavedProductConfig(
        '',
        '',
        new Product('', '', 0, 0, '', '', [], [], {}, [], null, null),
        []
      )
    );
  }

  ngOnInit() {
    this.formService.imageUrl$.subscribe((imageUrl) => {
      this.productImage = imageUrl;
    });

    this.header = this.entity
      ? this.entity.status
      : this.simulation
      ? 'newSimulation'
      : 'newProductConfig';

    (this.formService as ProductConfigFormService).setProducts(this.products$);

    this.initForm();
    this.initFields();

    if (this.preview) {
      this.actions.primary = null;
      this.actions.warn = { label: 'delete', color: 'warn' };
    }

    if (this.simulation) {
      (this.form.get('status') as UntypedFormControl).patchValue('NEW');
      (this.form.get('status') as UntypedFormControl).disable({
        onlySelf: true,
      });
    }
  }

  initFields() {
    const productStatus$ = new BehaviorSubject(
      Object.keys(ProductStatus).map((key) => ({
        id: ProductStatus[key],
        name: ProductStatus[key],
      }))
    );

    this.formFields = [
      { name: 'name', label: 'name', type: 'text', size: 6 },
      {
        name: 'status',
        label: 'status',
        type: 'select',
        size: 6,
        items$: productStatus$,
        nullable: false,
        translate: true,
      },
      {
        name: 'productId',
        label: 'productId',
        type: 'select',
        size: 12,
        items$: this.products$,
        nullable: true,
      },
    ];

    this.productFields = [
      [
        { type: 'separator', label: 'productInformation' },
        { name: 'name', label: 'name', type: 'text', size: 12 },
        { name: 'ref', label: 'reference', type: 'text', size: 6 },
        { name: 'stock', label: 'stock', type: 'number', size: 6 },
        {
          name: 'description',
          label: 'description',
          type: 'textarea',
          size: 12,
        },
      ],
    ];
  }

  get productSelected(): boolean {
    return this.form.get('productId').value !== '';
  }
}
