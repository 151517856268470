<div class="header">
  <span class="mat-subtitle-2">{{ title }}</span>
  <button
    mat-icon-button
    type="button"
    aria-label="Close"
    (click)="cancelForm()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="body">
  <crm-form-template
    [formGroup]="formGroup"
    [formFields]="formFields"
  ></crm-form-template>
</div>
<div class="footer">
  <button
    mat-flat-button
    color="primary"
    type="button"
    name="submit"
    (click)="submitForm()"
  >
    {{ 'save' | i18nextCap }}
  </button>
  <button
    *ngIf="isDeletable"
    mat-button
    color="warn"
    type="button"
    name="delete"
    (click)="deleteForm()"
  >
    {{ 'delete' | i18nextCap }}
  </button>
</div>
