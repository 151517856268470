import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'crm-parts-filters',
  templateUrl: './parts-filters.component.html',
  styleUrls: ['./parts-filters.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class PartsFiltersComponent implements OnInit {
  @Input() filters$: BehaviorSubject<{}> = new BehaviorSubject({});
  @Output() filtersChangeEvent = new EventEmitter<{}>();

  filtersForm: UntypedFormGroup;
  filtersFormFields = [];
  currentFilters$ = new BehaviorSubject({});

  ngOnInit() {
    this.filtersForm = new UntypedFormGroup({
      name: new UntypedFormControl('', []),
      price: new UntypedFormControl('', []),
    });

    this.filters$.subscribe((filters) => {
      Object.entries(filters).forEach(([key, value]) => {
        this.filtersForm.get(key).patchValue(value);
      });

      this.updateChips();
    });

    this.filtersFormFields = [
      { name: 'name', type: 'text', label: 'name', size: 12 },
    ];
  }

  updateChips() {
    const filters = this.filtersForm.value;
    let currentFilters = {};

    Object.keys(filters).forEach((key) => {
      if (
        filters[key] === null ||
        filters[key] === undefined ||
        filters[key].length === 0
      ) {
        delete filters[key];
      } else {
        // assumes all filters are strings
        currentFilters[key] = (filters[key] as string).toLowerCase();
      }
    });

    this.currentFilters$.next(currentFilters);
  }

  getFilterFormValueFormatted() {
    return Object.entries(this.filtersForm.value).reduce(
      (acc, [key, value]) => {
        if (value === undefined || value === null || value === '') return acc;

        return {
          ...acc,
          [key]: value,
        };
      },
      {}
    );
  }

  applyFilters() {
    this.filtersChangeEvent.emit(this.getFilterFormValueFormatted());
  }

  removeFilter(filter: string) {
    this.filtersForm.get(filter).patchValue(null);
    this.applyFilters();
  }
}
