import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { CreateWorkOrderCategoriesComponent } from '@fullyops/features/work-order-categories/containers/create-work-order-categories/create-work-order-categories.component';
import { EditWorkOrderCategoriesComponent } from '@fullyops/features/work-order-categories/containers/edit-work-order-categories/edit-work-order-categories.component';
import { WorkOrderCategoryModel } from '@fullyops/domain/work-order-categories/models/work-order-category.model';
import { WorkOrderCategoriesService } from '@fullyops/domain/work-order-categories/services/work-order-categories.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-work-order-categories',
  templateUrl: './work-order-categories.component.html',
  styleUrls: ['./work-order-categories.component.scss'],
})
export class WorkOrderCategoriesComponent {
  protected workOrderCategories$ = this.service.activeWorkOrderCategories$;
  protected noWorkOrderCategories$ = this.workOrderCategories$.pipe(
    map((categories) => categories.length === 0)
  );

  constructor(
    private service: WorkOrderCategoriesService,
    private dialog: MatDialog
  ) {
    this.service.get();
  }

  onActionEvent(event: string) {
    if (event === 'addNew') {
      this.openCreateCategoryModal();
    }
  }

  protected deleteCategory(id: string) {
    this.service.delete(id);
  }

  protected openCreateCategoryModal() {
    const createDialogRef = this.dialog.open(
      CreateWorkOrderCategoriesComponent,
      {}
    );
    createDialogRef.componentInstance.workOrderCategoryCreated$.subscribe(
      () => {
        createDialogRef.close();
      }
    );
  }

  protected openEditCategoryModal(category: WorkOrderCategoryModel) {
    const editDialogRef = this.dialog.open(EditWorkOrderCategoriesComponent, {
      data: category,
    });
    editDialogRef.componentInstance.workOrderCategoryEdited$.subscribe(() =>
      editDialogRef.close()
    );
  }

  protected readonly faTrashCan = faTrashCan;
  protected readonly faEdit = faEdit;
  protected readonly defaultTableHeaders = ['name', 'actions'];
}
