import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormGroup,
} from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionType } from '../form-select/form-select.component';

export type FieldTypes =
  | 'separator'
  | 'upload'
  | 'text'
  | 'button'
  | 'price'
  | 'number'
  | 'checkbox'
  | 'textarea'
  | 'select'
  | 'multiple-select'
  | 'fulldate'
  | 'date'
  | 'autocomplete'
  | 'chips-autocomplete'
  | 'slide'
  | 'images'
  | 'autocomplete-list';

type FieldTypesRequiringItems =
  | 'select'
  | 'multiple-select'
  | 'autocomplete'
  | 'chips-autocomplete'
  | 'autocomplete-list';

export type FieldItems = {
  id: string;
  name: string;
  color?: string;
  icon?: string;
  image?: string;
};

export interface BaseFormTemplateFields<T extends FormGroup> {
  name: string;
  type: FieldTypes;
  label: string;
  size: number;
  items$?: BehaviorSubject<FieldItems[]>;
  nullable?: boolean;
  translate?: boolean;
  color?: 'warn' | 'primary';
  onClick?: Function;
  button?: {
    fontAwesomeIconName?: IconDefinition;
    iconName?: string;
    onClick: Function;
    disabled$?: BehaviorSubject<boolean>;
    testId?: string;
  };
  dataTestId?: string;
  autocomplete?: {
    firstOption?: {
      label?: string;
      onClick?: any;
      fontAwesomeIcon?: IconDefinition;
      iconColor?: 'blue' | 'green' | 'red';
      value?: null | any;
    };
    value?: (item) => any;
    onInputChange?: {
      debounceTime?: number;
      callback?: any;
    };
    onTypeEnter?: {
      callback?: ({
        inputElement,
        chipsAutocompleteCtrl,
        formControl,
      }: {
        inputElement: HTMLInputElement;
        chipsAutocompleteCtrl?: FormControl;
        formControl?: AbstractControl<any, any>;
      }) => any;
    };
    chipsDisplayWith?: (e: any) => string;
    saveFullOptionValue?: boolean;
    onSelect?: () => any;
    getItemsSelected?: () => Array<SelectOptionType>;
  };
  fullDate?: {
    matDatepickerFilter?: Function;
    hourNeverDisabled?: boolean;
    canNotBeBeforeThan?: string;
  };
  inputImages?: {
    height?: number;
    imagesPerRow?: {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
      xl?: number;
    };
    accept?: string;
    type?: 'avatar';
    multipleImage?: boolean;
    uploadImageOnAdd?: boolean;
    isSignedImageResponseType: boolean;
    maxSizeInKB?: number;
    compressImage?: boolean;
  };
  matTooltip?: string;
  helperText?: string;
  displayValue?: (item) => string;
  hidden?: boolean;
  suffixIcon?: string;
  onSuffixClick?: () => void;
  placeholder?: string;
  select?: {
    onlyActiveOption?: boolean;
  };
}

interface FormTemplateFieldsWithItems<T extends FormGroup>
  extends BaseFormTemplateFields<T> {
  type: FieldTypesRequiringItems;
  items$: BehaviorSubject<SelectOptionType[] | any[]>;
}

interface FormTemplateFieldsWithoutItems<T extends FormGroup>
  extends BaseFormTemplateFields<T> {
  type: Exclude<FieldTypes, FieldTypesRequiringItems>;
  items$?: BehaviorSubject<SelectOptionType[] | any[]>;
}

export type FormTemplateFields<T extends FormGroup> =
  | FormTemplateFieldsWithItems<T>
  | FormTemplateFieldsWithoutItems<T>;

export type TemplateFormFields<T extends FormGroup> = FormTemplateFields<T>[];

@Component({
  selector: 'crm-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss'],
})
export class FormTemplateComponent {
  @Input() uploadService: (
    file: File,
    extension: string
  ) => BehaviorSubject<{ url: string }>;
  @Input() formGroup: UntypedFormGroup;
  @Input() formFields: TemplateFormFields<any>;
  @Output() selectionChange = new EventEmitter();
  @Output() buttonClick = new EventEmitter();
  @Input() index: number | undefined;
  @Output() inputChange = new EventEmitter();

  onButtonClick(event) {
    this.buttonClick.emit(event);
  }

  onSelectChange(event) {
    this.selectionChange.emit(event);
  }

  onInputChange() {
    this.inputChange.emit(this.formGroup);
  }

  uploadPDF(event, fieldName: string) {
    this.uploadService(event.target.files[0], 'pdf').subscribe((response) =>
      this.formGroup.get(fieldName).patchValue(response.url)
    );
  }
}
