import { TravelDataToEdit } from './../trips/trips.component';
import { map } from 'rxjs/operators';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidatorFn,
  ValidationErrors,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '@fullyops/legacy/data';
import {
  TravelRequest,
  TravelResponse,
} from '@fullyops/legacy/data/api/types/Travel';
import { FormTemplateFields } from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { TravelData } from '../trips/trips.component';
import { HotToastService } from '@ngneat/hot-toast';
import { I18NextPipe } from 'angular-i18next';
import { COLORS } from '@fullyops/legacy/ui/ui-shared/utils/styles';
import { ErrorStateMatcher } from '@angular/material/core';
import { UiAuthService } from '../ui-auth.service';
import { RoleGroup } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import * as moment from 'moment';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';
import { AssigneeService } from '@fullyops/core/services/assignees.service';
import { MatSelectChange } from '@angular/material/select';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  constructor(public timezoneDate: TimeZoneDateFormatterService) {}
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const startingHour = this.timezoneDate.formatInTimezone(
      form.form.controls['startingHour'].value
    );
    const endingHour = this.timezoneDate.formatInTimezone(
      form.form.controls['endingHour'].value
    );

    if (startingHour.toISOString() >= endingHour.toISOString()) {
      return true;
    }
    return false;
  }
}

@Component({
  selector: 'app-travel-card-v2',
  templateUrl: './travel-card-v2.component.html',
  styleUrls: ['./travel-card-v2.component.scss'],
})
export class TravelCardV2Component implements OnInit {
  @Input() travel: TravelResponse;
  @Input() isNew: Boolean;
  @Input() usersAssignees$: BehaviorSubject<User[]>;
  @Input() ticket$: BehaviorSubject<SupportTicketResponse>;
  @Input() openedIndex: number;
  @Input() index: number;
  @Input() isDetailPage: boolean;

  @Output() removeTravelEmitter = new EventEmitter<string>();
  @Output() createTravelEmitter = new EventEmitter<TravelData>();
  @Output() editTravelEmitter = new EventEmitter<TravelDataToEdit>();
  @Output() setOpenedIndex = new EventEmitter<any>();
  assignees$ = new BehaviorSubject<any[]>([]);
  timeHours = [];
  travelForm: UntypedFormGroup;
  matcher = new MyErrorStateMatcher(this.timezoneDate);
  moment = moment;

  maskConfig = {
    mask: [
      new RegExp('\\d'),
      new RegExp('\\d'),
      '/',
      new RegExp('\\d'),
      new RegExp('\\d'),
      '/',
      new RegExp('\\d'),
      new RegExp('\\d'),
      new RegExp('\\d'),
      new RegExp('\\d'),
    ],
    showMask: false,
    guide: false,
    placeholderChar: '_',
  };

  startingHourField: FormTemplateFields<any> = {
    name: 'startingHour',
    label: 'startDate',
    type: 'date',
    size: 12,
  };

  endingHourField: FormTemplateFields<any> = {
    name: 'endingHour',
    label: 'endDate',
    type: 'date',
    size: 12,
    fullDate: {
      hourNeverDisabled: true,
    },
  };

  constructor(
    private snackBarService: HotToastService,
    private i18nextPipe: I18NextPipe,
    protected authService: UiAuthService,
    public timezoneDate: TimeZoneDateFormatterService,
    private assigneeService: AssigneeService
  ) {}

  ngOnInit() {
    this.initHours();
    this.initForm();
    if (this.isDetailPage) this.travelForm.disable();
    this.onStayChangeDisableEndDate();
    this.loadUserOptions();
    this.onStartDateChangeWithStayUpdateEndDate();
  }

  onStartDateChangeWithStayUpdateEndDate() {
    this.travelForm.controls['startingHour'].valueChanges.subscribe((value) => {
      if (!this.travelForm.controls['stay'].value) {
        const newEndDate = moment(value).clone();
        const endDate = moment(this.travelForm.controls['endingHour'].value);
        newEndDate.hour(endDate.hour());
        newEndDate.minute(endDate.minute());

        this.travelForm.controls['endingHour'].setValue(newEndDate.toDate());
      }
    });
  }

  setInicialUserSelected() {
    const isUserAssigned = this.ticket$.value.assignees.some(
      (u) => u.id == this.authService.currentUser.id
    );

    const isUserTechnician = this.authService.hasRole(RoleGroup.TECHNICIAN);

    if (isUserTechnician && isUserAssigned) {
      this.travelForm.controls['assignees'].setValue([
        this.authService.currentUser$.value.id,
      ]);
    }
  }

  resetForm() {
    this.travelForm.reset({
      stay: false,
      distance: this.ticket$.value.company.distance,
      assignees: [],
      startingHour: this.getFormattedDate({
        date: this.timezoneDate.currentDate(),
      }),
      endingHour: this.getFormattedDate({
        date: this.timezoneDate.currentDate(),
        minutesShift: +this.ticket$.value.company.travelTime || 15,
      }),
    });
    this.setInicialUserSelected();
  }

  validateStartDateLogic(form: UntypedFormGroup): ValidationErrors | null {
    const dateGreaterError = {
      date: true,
      message: 'travelStartDateGreaterThanWorkEndDateShort',
    };

    const endHourControl = form.get('endingHour');
    const startingHourControl = form.get('startingHour');

    const startDate = moment(startingHourControl.value);
    const endDate = moment(endHourControl.value);

    if (!startDate.isValid() || !endDate.isValid()) {
      startingHourControl.setErrors(null);
      startingHourControl.markAsTouched();
      endHourControl.setErrors(null);
      endHourControl.markAsTouched();
      return null;
    }

    if (startDate.isSameOrAfter(endDate)) {
      startingHourControl.setErrors(dateGreaterError);
      startingHourControl.markAsTouched();
      endHourControl.setErrors(dateGreaterError);
      endHourControl.markAsTouched();
      return dateGreaterError;
    }

    startingHourControl.setErrors(null);
    startingHourControl.markAsTouched();
    endHourControl.setErrors(null);
    endHourControl.markAsTouched();
    return null;
  }

  validateStartDate(form: UntypedFormGroup) {
    return this.validateStartDateLogic(form) as ValidatorFn;
  }

  onStayChangeDisableEndDate() {
    this.travelForm.controls['stay'].valueChanges.subscribe((stay) => {
      if (stay) {
        return this.travelForm.controls['endingHour'].enable();
      }

      this.travelForm.controls['startingHour'].updateValueAndValidity();
      return this.travelForm.controls['endingHour'].disable();
    });
  }

  ifStayUpdateEndDate() {
    const stay = this.travelForm.get('stay').value;
    const startingHourControl = this.travelForm.get('startingHour');
    const startingHour = startingHourControl.value;
    if (!stay) {
      this.travelForm.controls['endingHour'].setValue(
        this.getFormattedDate({
          date: this.timezoneDate.formatInTimezone(startingHour),
          minutesShift: +this.ticket$.value.company.travelTime || 15,
        })
      );
    }

    this.travelForm.controls['endingHour'].updateValueAndValidity();
  }

  setOpenedExpansionPainelIndex(index: number) {
    return this.setOpenedIndex.emit(index);
  }

  getFormattedDate(props: { minutesShift?: number; date: moment.Moment }) {
    const { date, minutesShift } = props;
    const momentDate = date.clone();
    const roundedMinutes = Math.ceil(momentDate.minutes() / 15) * 15;

    momentDate.minutes(roundedMinutes + (minutesShift ? minutesShift : 0));
    return momentDate.toDate();
  }

  initForm() {
    this.travelForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.travel?.id || null),
      assignees: new UntypedFormControl([], Validators.required),
      stay: new UntypedFormControl(false, [Validators.required]),
      distance: new UntypedFormControl(0, Validators.required),
      endingHour: new UntypedFormControl(
        this.getFormattedDate({
          date: this.timezoneDate.currentDate(),
          minutesShift: this.ticket$.value.company.travelTime || 15,
        }),
        { validators: [Validators.required], updateOn: 'change' }
      ),
      startingHour: new UntypedFormControl(
        this.getFormattedDate({ date: this.timezoneDate.currentDate() }),
        { validators: [Validators.required], updateOn: 'change' }
      ),
    });

    this.travelForm.controls['endingHour'].disable();

    this.travelForm.controls['startingHour'].addValidators(() =>
      this.validateStartDate(this.travelForm)
    );
    this.travelForm.controls['endingHour'].addValidators(() =>
      this.validateStartDate(this.travelForm)
    );

    this.travelForm.controls['endingHour'].valueChanges.subscribe(() => {
      this.validateStartDate(this.travelForm);
    });

    this.setInicialUserSelected();

    if (!this.travel) {
      return this.travelForm.controls['distance'].setValue(
        this.ticket$.value.company.distance
      );
    }

    this.travelForm.controls['distance'].setValue(this.travel.distance);
    this.travelForm.controls['assignees'].setValue(
      this.travel.assignees.map((assignee) => assignee.assignee.id)
    );
    this.travelForm.controls['startingHour'].setValue(this.travel.startingHour);
    this.travelForm.controls['endingHour'].setValue(this.travel.endingHour);
    this.travelForm.controls['stay'].setValue(this.travel.stay);
  }

  loadUserOptions() {
    this.usersAssignees$
      .pipe(
        map((users) => {
          return this.assigneeService.getDropdownAssigneeList({
            formValue: this.travel?.assignees?.map((e) => e.assignee) || [],
            listOfUsers: users,
          });
        })
      )
      .subscribe(this.assignees$);
  }

  initHours() {
    this.timeHours = new Array(96).fill('', 0, 96).map((_, index) => {
      const value = Math.floor(index / 4);
      if (index % 4 === 0) {
        return `${value < 10 ? `0${value}` : value}:00`;
      } else {
        return `${value < 10 ? `0${value}` : value}:${(index % 4) * 15}`;
      }
    });
  }

  removeTravel() {
    this.removeTravelEmitter.emit(this.travel.id);
  }

  createTravel() {
    this.travelForm.markAllAsTouched();

    if (!this.travelForm.valid) {
      return this.snackBarService.error(
        this.i18nextPipe.transform('forms:SomeFieldIsInvalid'),
        {
          position: 'bottom-right',
          duration: 5000,
          dismissible: true,
          style: {
            color: 'white',
            backgroundColor: COLORS.red,
          },
          icon: '⚠️',
        }
      );
    }

    const newTravel: TravelRequest = {
      distance: this.travelForm.controls['distance'].value,
      endingHour: this.travelForm.controls['endingHour'].value,
      installationTicketId: null,
      startingHour: this.travelForm.controls['startingHour'].value,
      stay: this.travelForm.controls['stay'].value,
      supportTicketId: this.ticket$.value.id,
    };
    const assigneeIds = this.travelForm.controls['assignees'].value;

    if (this.isNew) this.resetForm();

    this.createTravelEmitter.emit({
      travelBasicData: newTravel,
      assigneesIds: assigneeIds,
    });
  }

  timeChange(matSelectChange: MatSelectChange, formControlName: string) {
    const newTime = matSelectChange.value;
    const control = this.travelForm.controls[formControlName];
    const date = this.timezoneDate.formatInTimezone(control.value).toDate();
    const hour = newTime.substring(0, 2);
    const minutes = newTime.substring(3, 5);

    date.setHours(+hour);
    date.setMinutes(+minutes);
    control.patchValue(date);
    this.travelForm.markAsTouched();
  }

  editTravel() {
    const { controls } = this.travelForm;

    if (!this.travelForm.valid) {
      this.travelForm.markAllAsTouched();

      return this.snackBarService.error(
        this.i18nextPipe.transform('forms:SomeFieldIsInvalid'),
        {
          position: 'bottom-right',
          duration: 5000,
          dismissible: true,
          style: {
            color: 'white',
            backgroundColor: COLORS.red,
          },
          icon: '⚠️',
        }
      );
    }

    const travelToEdit: TravelDataToEdit = {
      assigneesIds: controls['assignees'].value,
      oldTravelData: this.travel,
      travelBasicData: {
        id: controls['id'].value,
        distance: controls['distance'].value,
        endingHour: controls['endingHour'].value,
        installationTicketId: this.travel.installationTicketId,
        startingHour: controls['startingHour'].value,
        stay: controls['stay'].value,
        supportTicketId: this.ticket$.value.id,
      },
    };

    this.editTravelEmitter.emit(travelToEdit);
  }

  getToolTipText() {
    return this.i18nextPipe.transform(
      'translation:changeEndDateWithStayOnActivated'
    );
  }
}
