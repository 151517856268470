import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavPortalBridgeService {
  private activePortal = new Subject<ComponentPortal<any>>();

  readonly portal$ = this.activePortal.asObservable();

  constructor(private injector: Injector) {}

  openPortal(component, DATA_TOKEN, args) {
    const portalInjector = Injector.create({
      providers: [{ provide: DATA_TOKEN, useValue: args }],
      parent: this.injector,
    });

    const portal = new ComponentPortal(component, null, portalInjector);

    this.activePortal.next(portal);
  }

  closePortal() {
    this.activePortal.next(null);
  }
}
