<div mat-dialog-content>
  <div class="header">
    <h2 mat-dialog-title>{{ 'forms:digitalSignature' | i18nextCap }}</h2>
    <span class="secondary-color">{{
      'forms:clientConsent' | i18nextCap
    }}</span>
  </div>
  <crm-signature-pad
    (changeEvent)="onSignatureChange($event)"
  ></crm-signature-pad>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'forms:close' | i18nextCap }}</button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="data"
    [disabled]="!data"
    [attr.data-testid]="'signature-pad-sign'"
  >
    {{ 'forms:generate' | i18nextCap }}
  </button>
</div>
