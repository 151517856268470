import { Injectable, Inject } from '@angular/core';
import { DialogComponent, DialogData } from './dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(public dialog: MatDialog) {}

  openDialogBeforeDelete() {
    return this.dialog.open(DialogComponent, {
      data: {
        title: 'areYouSureYouWantToDelete?',
        message: 'onceDeletedThisActionCannotBeUndone',
        closeModalText: 'cancel',
        actionModalText: 'delete',
      },
    });
  }

  openDialog(params: DialogData) {
    return this.dialog.open(DialogComponent, {
      data: params,
    });
  }
}
