import { Component, Input, OnInit } from '@angular/core';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import { PermissionType } from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { MachineFormService } from './machine-form.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Company,
  ClientEquipment,
  UnsavedClientEquipment,
} from '@fullyops/legacy/data';
import { FormComponent } from '@fullyops/legacy/ui/ui-shared/utils/form.component';
import { BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SnackbarService } from '../ui-snackbar.service';
import { UiAuthService } from '../ui-auth.service';

@Component({
  selector: 'crm-machine-form',
  templateUrl: './machine-form.component.html',
  styleUrls: ['./machine-form.component.scss'],
  providers: [MachineFormService],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class MachineFormComponent
  extends FormComponent<
    ClientEquipment,
    UnsavedClientEquipment,
    MachineFormService
  >
  implements OnInit
{
  @Input() suppliers$: BehaviorSubject<Company[]>;

  suppliersL$ = new BehaviorSubject<[]>([]);

  constructor(
    public machineFormService: MachineFormService,
    public snackBar: SnackbarService,
    public authService: UiAuthService
  ) {
    super(snackBar, authService);

    this.formService = machineFormService;
    this.formEditPermissions = [PermissionType.CAN_EDIT_CLIENT_EQUIPMENTS];
    this.draft$ = new BehaviorSubject<UnsavedClientEquipment>(
      new UnsavedClientEquipment(
        '',
        null,
        '',
        null,
        '',
        false,
        '',
        '',
        '',
        '',
        '',
        null,
        ''
      )
    );
  }

  ngOnInit() {
    this.initForm();
    this.initFields();
  }

  initFields() {
    this.formFields = [
      { name: 'brand', type: 'text', label: 'brand', size: 6 },
      { name: 'model', type: 'text', label: 'model', size: 6 },
      { name: 'serialNumber', type: 'text', label: 'serialNumber', size: 6 },
      { name: 'type', type: 'text', label: 'type', size: 6 },
      {
        name: 'equipmentData',
        type: 'textarea',
        label: 'additionalInformation',
        size: 12,
      },
      { name: 'warranty', type: 'date', label: 'warranty', size: 6 },
      {
        name: 'hasWarranty',
        type: 'checkbox',
        label: 'hasWarranty',
        size: 6,
      },
    ];
  }
}
