<crm-breadcrumb
  [header]="part ? part.name : 'newPart'"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
  [maxSize]="'xl'"
></crm-breadcrumb>

<crm-container [maxSize]="'xl'">
  <form
    #ngForm="ngForm"
    [@inOut]="'in'"
    (ngSubmit)="onSubmit()"
    *ngIf="formGroup"
  >
    <div class="form-row">
      <div class="col-md-12">
        <crm-form-template
          [formGroup]="formGroup"
          [formFields]="formFields"
        ></crm-form-template>
        <button
          type="submit"
          mat-flat-button
          color="primary"
          [disabled]="!(isDirty$ | async)"
        >
          {{ 'forms:save' | i18nextCap }}
        </button>
      </div>
    </div>
    <footer></footer>
  </form>
</crm-container>
