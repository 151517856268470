import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { I18NextService } from 'angular-i18next';
import { BehaviorSubject, Observable } from 'rxjs';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { SaleTicketResponse } from '@fullyops/legacy/data/api/types/Ticket';
import { ReportType } from '@fullyops/legacy/data/api/types/Report';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { SnackbarService } from '@fullyops/legacy/ui/ui-crm/ui-snackbar.service';
import { ReportControllerControllerV2 } from '@fullyops/legacy/ui/ui-crm/ui-report-controller-v2.service';
import { TenantConfigurationController } from '@fullyops/legacy/ui/ui-crm/ui-tenant-configuration-v2-controller.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipGrid } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

type EmailsList = 'bccList' | 'ccList';

export interface ReportListDialogData {
  ticket$: BehaviorSubject<SupportTicketResponse | SaleTicketResponse>;
  reportId: string;
  reportType: ReportType;
}

@Component({
  selector: 'app-report-list-dialog',
  templateUrl: './report-list-dialog.component.html',
  styleUrls: ['./report-list-dialog.component.scss'],
})
export class ReportListDialogComponent implements OnInit {
  allContactsEmail: string[] = [];
  allEmails: string[] = [];

  filteredCCContactsEmail: Observable<string[]>;
  ccList: string[] = [];
  ccEmailControl = new FormControl('');

  filteredBCCContactsEmail: Observable<string[]>;
  bccList: string[] = [];
  bccEmailControl = new FormControl('');

  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  REPORT_INTERNAL_EMAIL = '';

  @ViewChild('ccInput') ccInput: ElementRef<HTMLInputElement>;
  @ViewChild('bccInput') bccInput: ElementRef<HTMLInputElement>;
  @ViewChild('ccEmailChipList') ccEmailChipList: MatChipGrid;
  @ViewChild('bccEmailChipList') bccEmailChipList: MatChipGrid;

  constructor(
    public dialogRef: MatDialogRef<ReportListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportListDialogData,
    private snackBarService: SnackbarService,

    private reportService: ReportControllerControllerV2,
    private tenantService: TenantConfigurationController,
    private translateService: I18NextService
  ) {
    this.filteredCCContactsEmail = this.ccEmailControl.valueChanges.pipe(
      startWith(null),
      map((email: string | null) => this._filter(email, 'ccList'))
    );

    this.filteredBCCContactsEmail = this.bccEmailControl.valueChanges.pipe(
      startWith(null),
      map((email: string | null) => this._filter(email, 'bccList'))
    );
  }

  ngOnInit(): void {
    this.loadAllContactsEmail();
    this.initValues();
    this.data.ticket$.subscribe((value) => {
      if (!value?.company?.email) return;
      this.setWarningState(value.company.email);
      this.loadAllEmails();
    });

    this.ccEmailControl.valueChanges.subscribe((value) => {
      const { ccEmailChipList, ccEmailControl } = this;
      if (!value) return (ccEmailChipList.errorState = false);

      if (ccEmailControl.valid) return (ccEmailChipList.errorState = false);

      if (!ccEmailControl.valid) return (ccEmailChipList.errorState = true);
    });
  }

  initValues() {
    if (this.allContactsEmail.length > 0) {
      this.ccList.push(...this.allContactsEmail);
    }
    const companyEmail = this.data.ticket$.value.company.email;
    if (companyEmail) this.ccList.push(companyEmail);

    this.loadDefaultEmail();
  }

  loadDefaultEmail() {
    let label = '';
    if (this.data.reportType == 'SALE_REPORT') {
      label = 'SALE_TICKET_REPORT_INTERNAL_EMAIL';
    }

    if (this.data.reportType == 'SUPPORT_REPORT') {
      label = 'SUPPORT_TICKET_REPORT_INTERNAL_EMAIL';
    }

    if (
      label == 'SALE_TICKET_REPORT_INTERNAL_EMAIL' ||
      label == 'SUPPORT_TICKET_REPORT_INTERNAL_EMAIL'
    ) {
      this.tenantService
        .getConfigurationsByLabel({
          label,
        })
        .subscribe((t) => {
          if (!t.value) {
            this.tenantService
              .getConfigurationsByLabel({ label: 'TENANT_EMAIL' })
              .subscribe((res) => {
                this.REPORT_INTERNAL_EMAIL = res.value;
                this.bccList.push(res.value);
                this.loadAllEmails();
              });
          } else {
            this.bccList.push(t.value);
            this.REPORT_INTERNAL_EMAIL = t.value;
            this.loadAllEmails();
          }
        });
    }
    const companyEmail = this.data.ticket$.value.company.email;
    if (companyEmail) this.allEmails.push(companyEmail);

    this.ccEmailControl.updateValueAndValidity();
    this.bccEmailControl.updateValueAndValidity();
  }

  loadAllEmails() {
    this.allEmails = [...this.allContactsEmail];
    if (this.REPORT_INTERNAL_EMAIL) {
      this.allEmails.push(this.REPORT_INTERNAL_EMAIL);
    }
    const companyEmail = this.data.ticket$.value.company.email;
    if (companyEmail) this.allEmails.push(companyEmail);

    this.ccEmailControl.updateValueAndValidity();
    this.bccEmailControl.updateValueAndValidity();
  }

  loadAllContactsEmail() {
    this.allContactsEmail = this.data.ticket$.value?.contacts
      .filter((c) => c.email)
      .map((e) => e.email);
  }

  setWarningState(companyEmail: string) {
    if (this.allContactsEmail.length == 0 && !companyEmail) {
      this.ccEmailChipList.errorState = true;
      this.ccEmailControl.setErrors({ message: 'error:reportListWarning' });
    }
  }

  onNoClick() {
    this.dialogRef.close();
  }

  sendEmail() {
    if (this.ccList.length == 0 && this.bccList.length == 0) {
      return this.snackBarService.openError({
        message: 'error:youMustSelectAtLeastOneEmail',
      });
    }
    const props = {
      reportId: this.data.reportId,
      recipients: this.ccList,
      bccRecipients: this.bccList,
    };
    this.reportService.sendEmailReportWithCustomEmails(props).subscribe((r) => {
      this.dialogRef.close();
    });
  }

  remove(email, list: EmailsList) {
    const index = this[list].indexOf(email);

    if (index >= 0) {
      this[list].splice(index, 1);
    }

    this.ccEmailControl.updateValueAndValidity();
    this.bccEmailControl.updateValueAndValidity();
  }

  add(event: HTMLInputElement, list: EmailsList) {
    if (!event.value) return;

    if (this[list].includes(event.value)) {
      return this.snackBarService.openError({
        message: 'error:thisEmailHasAlreadyBeenAdded',
      });
    }

    if (event.value) {
      if (!this.isValidEmail(event.value)) {
        if (list == 'bccList') this.bccEmailControl.setErrors({ error: true });
        if (list == 'ccList') this.ccEmailControl.setErrors({ error: true });
        return;
      }
      this[list].push(event.value);
    }

    event.value = null;

    this.ccEmailControl.setValue(null);
    this.ccEmailControl.updateValueAndValidity();

    this.bccEmailControl.setValue(null);
    this.bccEmailControl.updateValueAndValidity();
  }

  selected(event: MatAutocompleteSelectedEvent, list: EmailsList) {
    this[list].push(event.option.viewValue);

    this.ccInput.nativeElement.value = '';
    this.ccEmailControl.setValue(null);
    this.ccEmailControl.updateValueAndValidity();

    this.bccInput.nativeElement.value = '';
    this.bccEmailControl.setValue(null);
    this.bccEmailControl.updateValueAndValidity();
  }

  private _filter(value: string, list: EmailsList): string[] {
    const notAddedEmails = this.allEmails.filter(
      (email) => !this[list].includes(email)
    );

    if (!value) return notAddedEmails;

    const filteredList = notAddedEmails.filter((email) =>
      email.startsWith(value)
    );

    return filteredList;
  }

  isValidEmail = (email) => {
    // Regular expression pattern for a basic email validation
    const emailPattern =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    // Test the email against the pattern
    if (!emailPattern.test(email)) {
      this.openSnackBarInvalidEmail();
      return false;
    }
    return true;
  };

  openSnackBarInvalidEmail() {
    this.snackBarService.openError({ message: 'please select a valid email' });
  }
}
