import { ApiActionTypeAdapterService } from '@fullyops/data/action-types/api-action-types-adapter.service';
import { Observable, map } from 'rxjs';
import {
  InterventionTypeRequest,
  InterventionTypeResponse,
} from '@fullyops/data/action-types/entities/action-type-entity';
import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  withEntities,
  updateEntities,
  updateAllEntities,
  setEntitiesMap,
  setEntities,
  selectAllEntities,
} from '@ngneat/elf-entities';

@Injectable({
  providedIn: 'root',
})
export class ActionTypesService {
  private store = createStore(
    { name: 'action-types' },
    withEntities<InterventionTypeResponse, 'label'>({ idKey: 'label' })
  );

  actionTypes$: Observable<InterventionTypeResponse[]> = this.store.pipe(
    selectAllEntities()
  );

  constructor(private adapter: ApiActionTypeAdapterService) {}

  getAll(): Observable<InterventionTypeResponse[]> {
    this.adapter
      .getAll()
      .subscribe((res) => this.store.update(setEntities(res)));
    return this.actionTypes$;
  }

  private optimisticUpdate<T extends keyof InterventionTypeRequest>(
    label: string,
    key: T,
    fieldOp: (v: InterventionTypeRequest[T]) => InterventionTypeRequest[T]
  ) {
    const origValue = this.store.getValue().entities[label][key];
    this.store.update(
      updateEntities(label, (it) => ({ ...it, [key]: fieldOp(it[key]) }))
    );
    this.adapter.update(label, { [key]: fieldOp(origValue) }).subscribe({
      error: (_) =>
        this.store.update(
          updateEntities(label, (s) => ({ ...s, [key]: origValue }))
        ),
    });
  }

  toggleActive(label: string) {
    this.optimisticUpdate(label, 'active', (a) => !a);
  }

  setColor(label: string, color: string) {
    this.optimisticUpdate(label, 'color', (_) => color);
  }

  setAsDefault(label: string) {
    const orig = this.store.getValue();
    this.store.update(
      updateAllEntities((s) => ({ ...s, isDefault: s.label === label }))
    );
    this.adapter.update(label, { isDefault: true }).subscribe({
      error: (_) => this.store.update(setEntitiesMap(orig.entities)),
    });
  }
}
