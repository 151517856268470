<div
  class="auto-margin padding-bottom height"
  [ngClass]="{
    'max-w-screen-sm': maxSize == 'sm',
    'max-w-screen-md': maxSize == 'md',
    'max-w-screen-lg': maxSize == 'lg',
    'max-w-screen-xl': maxSize == 'xl',
    'max-w-screen-2xl': maxSize == '2xl',
    'no-padding': noPadding == true,
    'no-padding-top': noPaddingTop == true,
    'half-vertical-padding': halfVerticalPadding == true
  }"
>
  <ng-content></ng-content>
</div>
