<section>
  <header>
    <p>
      {{ 'forms:' + title | i18nextCap }}
      <button
        *ngIf="!preview && createEntryLabel"
        mat-flat-button
        type="button"
        color="primary"
        (click)="addSection(true)"
      >
        {{ 'forms:' + createEntryLabel | i18nextCap }}
      </button>
    </p>
    <p class="subheader">{{ 'forms:' + subtitle | i18nextCap }}</p>
  </header>
  <mat-tab-group
    class="margin-0"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="selected.setValue($event)"
  >
    <mat-tab
      *ngFor="let section of sectionsForm.controls; let index = index"
      [label]="section.value.section"
      [formGroup]="$any(section)"
    >
      <mat-toolbar>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'forms:sectionName' | i18nextCap }}</mat-label>
          <input matInput formControlName="section" />
        </mat-form-field>
        <span class="spacer"></span>
        <button
          *ngIf="!preview"
          mat-button
          color="warn"
          type="button"
          (click)="removeSection(index)"
        >
          {{ 'forms:removeSection' | i18nextCap }}
        </button>
        <button
          *ngIf="!preview"
          mat-button
          color="primary"
          type="button"
          (click)="addSubsection(index)"
        >
          {{ 'forms:addSubsection' | i18nextCap }}
        </button>
      </mat-toolbar>
      <ng-container
        *ngFor="
          let subsection of $any(section).controls['content'].controls;
          let subIndex = index
        "
      >
        <div class="form-separator">
          <hr />
          <span>{{ 'forms:' + subsection.value.title | i18nextCap }}</span>
        </div>
        <crm-product-form-details-subsection
          [preview]="preview"
          [title]="subsection.value.title"
          subtitle="subsection"
          createEntryLabel="addSubsection"
          [subsectionForm]="subsection"
          [uploadService]="uploadService"
          (removeEvent)="removeSubsection(index, subIndex)"
        ></crm-product-form-details-subsection>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</section>
