<form [formGroup]="formGroup">
  <div
    class="crm-configuration-forms-item"
    *ngFor="let configuration of tenantConfiguration$ | async"
  >
    <ng-container [ngSwitch]="configuration.type">
      <div *ngSwitchCase="'BOOLEAN'" class="field-and-info-icon margin-bottom">
        <mat-slide-toggle
          color="primary"
          [formControlName]="configuration.label"
        >
          {{ configuration.label | i18nextCap }}
        </mat-slide-toggle>
        <div
          class="icon blue-icon no-margin-bottom"
          [matTooltip]="configuration.description | i18nextCap"
          *ngIf="configuration.description"
        >
          <fa-icon [icon]="faCircleInfo" size="xl"></fa-icon>
        </div>
      </div>

      <div *ngSwitchCase="'EMAIL'" class="field-and-info-icon">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ configuration.label | i18nextCap }}</mat-label>
          <input
            matInput
            placeholder="email@example.com"
            [formControlName]="configuration.label"
          />
          <mat-error>{{
            getFieldErrorMessage(configuration.label) | i18nextCap
          }}</mat-error>
        </mat-form-field>
        <div
          class="icon blue-icon"
          [matTooltip]="configuration.description | i18nextCap"
        >
          <fa-icon [icon]="faCircleInfo" size="xl"></fa-icon>
        </div>
      </div>

      <div *ngSwitchCase="'INTEGER'" class="field-and-info-icon">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ configuration.label | i18nextCap }}</mat-label>
          <input
            class="number"
            matInput
            type="number"
            [min]="configuration.options.min || 0"
            [max]="configuration.options.max || undefined"
            step="1"
            [formControlName]="configuration.label"
            [required]="configuration.required"
          />
          <mat-error>{{
            getFieldErrorMessage(configuration.label)
              | i18nextCap
                : {
                    max: configuration.options.max,
                    min: configuration.options.min
                  }
          }}</mat-error>
        </mat-form-field>

        <div
          class="icon blue-icon"
          [matTooltip]="configuration.description | i18nextCap"
          *ngIf="configuration.description"
        >
          <fa-icon [icon]="faCircleInfo" size="xl"></fa-icon>
        </div>
      </div>

      <div *ngSwitchCase="'SELECT'">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{ configuration.label | i18nextCap }}</mat-label>
          <mat-select
            [formControlName]="configuration.label"
            [required]="configuration.required"
          >
            <ng-container *ngFor="let option of configuration.options.values">
              <mat-option [value]="option">
                {{ option | i18nextCap }}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error>{{
            getFieldErrorMessage(configuration.label) | i18nextCap
          }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'TEXT'">
        <mat-form-field class="d-block" class="full-width" appearance="outline">
          <mat-label>{{ configuration.label | i18nextCap }}</mat-label>
          <input
            matInput
            class="full-width"
            [formControlName]="configuration.label"
            [required]="configuration.required"
          />
          <mat-error>{{
            getFieldErrorMessage(configuration.label) | i18nextCap
          }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngSwitchCase="'IMAGE'">
        <crm-drop-input
          [formControlElement]="$any(formGroup.controls[configuration.label])"
          [errorMessage]="
            getFieldErrorMessage(configuration.label) | i18nextCap
          "
          [height]="200"
          [description]="configuration.description | i18nextCap"
          [label]="configuration.label"
          [configuration]="configuration"
          (uploadTemplateEmitter)="uploadImage($event, configuration.label)"
        ></crm-drop-input>
      </div>
    </ng-container>
  </div>
  <!-- <button mat-flat-button color="primary" (click)="submit()">
    {{ 'save' | i18nextCap }}
  </button> -->
</form>
