<crm-breadcrumb
  [header]="subProduct ? subProduct.name : 'newProduct'"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
  [maxSize]="'xl'"
></crm-breadcrumb>
<ng-container
  *ngIf="subProductFormService.subProductForm$ | async as subProductForm"
>
  <form [@inOut]="'in'" [formGroup]="subProductForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
      <div class="col-md-6">
        <crm-form-template
          [formGroup]="subProductForm"
          [formFields]="formFields"
        ></crm-form-template>
      </div>
      <div class="col-md-12">
        <span class="section">{{ 'forms:variants' | i18nextCap }}</span>
        <ng-container *ngIf="subProduct">
          <crm-sub-product-variants
            [variants]="subProduct.variants"
            (addVariantEvent)="addVariant()"
            (editVariantEvent)="editVariant($event)"
          ></crm-sub-product-variants>
        </ng-container>
      </div>
    </div>
  </form>
</ng-container>
