<crm-breadcrumb
  *ngIf="user$ | async as user"
  header="{{ user.firstName }} {{ user.lastName }}"
  [actionButtons]="actions"
  [maxSize]="'xl'"
></crm-breadcrumb>
<div class="container">
  <mat-form-field class="d-block" appearance="outline">
    <mat-label>{{ 'forms:search' | i18nextCap }}</mat-label>
    <input matInput [formControl]="partSearchCtrl" autocomplete="off" />
  </mat-form-field>

  <ul *ngIf="filteredParts$ | async as filteredParts">
    <div *ngIf="user$ | async as user" mat-dialog-title>
      {{ user.firstName }} {{ user.lastName }} {{ 'collection' | i18nextCap }}
    </div>
    <mat-divider></mat-divider>
    <li *ngFor="let userPart of filteredParts[1]" class="light-green-50">
      <button
        mat-button
        type="button"
        color="warn"
        (click)="onRemovePart(userPart)"
      >
        {{ 'remove' | i18nextCap }}
      </button>
      <span>{{ userPart.part.name }}</span>
      <div class="quantity">
        <label>{{ 'quantity' | i18nextCap }}</label>
        <input
          mat-input
          type="number"
          min="0"
          step="1"
          [value]="userPart.quantity"
          (change)="onChangeQuantity($event, userPart)"
        />
      </div>
    </li>
    <crm-empty-warn
      *ngIf="!filteredParts[1].length"
      message="{{ 'error:noUserPartsMatchTheSearch' | i18nextCap }} '{{
        partSearchCtrl.value
      }}'"
    ></crm-empty-warn>

    <div mat-dialog-title>Tenant {{ 'collection' | i18nextCap }}</div>
    <mat-divider></mat-divider>
    <li *ngFor="let part of filteredParts[0]">
      <button
        mat-button
        type="button"
        color="primary"
        (click)="onAddPart(part)"
      >
        {{ 'add' | i18nextCap }}
      </button>
      {{ part.name }}
    </li>
    <crm-empty-warn
      *ngIf="!filteredParts[1].length"
      message="{{ 'error:noTenantPartsMatchTheSearch' | i18nextCap }} '{{
        partSearchCtrl.value
      }}'"
    ></crm-empty-warn>
  </ul>
</div>
