<!-- <crm-breadcrumb
  [header]="header"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
></crm-breadcrumb> -->
<crm-container maxSize="xl" [noPadding]="true">
  <div class="work-order-header">
    <fo-breadcrumb></fo-breadcrumb>
    <button mat-stroked-button color="warn" (click)="onDelete()">
      {{ 'delete' | i18nextCap }}
    </button>
  </div>
</crm-container>
<crm-container [maxSize]="'xl'" [noPaddingTop]="true">
  <ng-container
    *ngIf="
      ticketSupportMultimachineFormService.form$
        | async as ticketSupportMultimachineForm
    "
  >
    <form
      #ngForm="ngForm"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      class="main-form remove-form-margin-and-padding"
    >
      <div class="row mx-0 overflow-auto">
        <!-- Left -->
        <div class="left-col col-md-8">
          <mat-tab-group
            (selectedTabChange)="onTabClick($event)"
            [selectedIndex]="selected.value"
            (selectedIndexChange)="selected.setValue($event)"
            class="remove-mat-tab-group-margins"
          >
            <!-- Info table  -->
            <mat-tab [attr.data-testid]="'ticket-support-info-tab'">
              <ng-template mat-tab-label
                >{{ 'forms:info' | i18nextCap }}
              </ng-template>
              <div class="tab-content">
                <form class="info-tab-form" (ngSubmit)="updateTicket({})">
                  <crm-form-template
                    [formGroup]="infoTabFormGroup"
                    [formFields]="infoTabFormFields"
                  ></crm-form-template>
                  <button
                    type="submit"
                    mat-flat-button
                    color="primary"
                    class="button-fix"
                    [disabled]="isSubmitInfoTabDisabled$ | async"
                  >
                    {{ 'forms:save' | i18nextCap }}
                  </button>
                </form>
              </div>
            </mat-tab>

            <!-- Actions table  -->
            <ng-container *hasPermission="{ permissions: requestPermissions }">
              <mat-tab
                *ngIf="entity"
                [attr.data-testid]="'ticket-support-intervention-tab'"
              >
                <ng-template mat-tab-label
                  >{{ 'translation:actions' | i18nextCap }}
                </ng-template>
                <div class="tab-content" *ngIf="ticket$ | async">
                  <crm-work-order-actions
                    [isDetailPage]="preview"
                    [ticket$]="ticket$"
                    [ticketId]="ticketId"
                    (openRequestTab)="openRequestTab()"
                    [openedIndex]="accordionOpenedIndex"
                    (setAccordionTabIndex)="setAccordionTabIndex($event)"
                  ></crm-work-order-actions>
                </div>
              </mat-tab>
            </ng-container>

            <!-- Requests table  -->
            <ng-container *hasPermission="{ permissions: requestPermissions }">
              <mat-tab
                *ngIf="entity"
                [attr.data-testid]="'ticket-support-request-tab'"
              >
                <ng-template mat-tab-label>
                  {{ 'forms:partRequests' | i18nextCap }}
                </ng-template>
                <div class="tab-content">
                  <crm-ticket-support-request-parts
                    [isDetailPage]="preview"
                    [ticket]="$any(ticket)"
                    [formGroup]="
                      $any(ticketSupportMultimachineForm.controls.ticketParts)
                    "
                    [users$]="technician$"
                    [usersParts$]="usersParts$"
                    [requests]="entity.requests"
                    (openEvent)="openTicketRequest($any($event))"
                    (removePartEvent)="removePart($event)"
                    (addPartEvent)="addPart($event)"
                  ></crm-ticket-support-request-parts>
                </div>
              </mat-tab>
            </ng-container>

            <!-- Reports table  -->
            <ng-container *hasPermission="{ permissions: reportPermissions }">
              <mat-tab
                *ngIf="entity"
                [attr.data-testid]="'ticket-support-reports-tab'"
              >
                <ng-template mat-tab-label
                  >{{ 'forms:reports' | i18nextCap }}
                </ng-template>
                <div class="tab-content">
                  <crm-report-list
                    [ticketId]="ticket.id"
                    [isDetailPage]="preview"
                    [withProposal]="true"
                    [reportType]="reportType"
                    [reports$]="reports$"
                    [ticket$]="ticket$"
                  ></crm-report-list>
                </div>
              </mat-tab>
            </ng-container>
          </mat-tab-group>
        </div>

        <!-- Right -->
        <div class="right-col col-md-4">
          <div class="d-flex flex-column right-column-gap overflow-auto">
            <mat-accordion>
              <crm-expansion-panel title="{{ 'details' | i18nextCap }}">
                <crm-form-template
                  [formGroup]="detailsFormGroup"
                  [formFields]="formDetailsFields"
                ></crm-form-template>
              </crm-expansion-panel>

              <crm-working-hours-registry
                [isDetailPage]="preview"
                [usersAssignees$]="assignees$"
                [ticket$]="ticket$"
                *ngIf="ticket"
              ></crm-working-hours-registry>

              <div class="ticket-creation-info">
                <span>
                  {{ 'createdBy' | i18nextCap }}
                  {{ getCreatedByName() }}
                </span>
                <span
                  >{{ 'createdAt' | i18nextCap }}:
                  {{
                    this.ticket.createdAt
                      | momentTimezoneToAngularTimezone: 'DD/MM/YYYY HH:mm'
                  }}</span
                >
                <span
                  >{{ 'lastUpdatedAt' | i18nextCap }}:
                  {{
                    this.ticket.updatedAt
                      | momentTimezoneToAngularTimezone: 'DD/MM/YYYY HH:mm'
                  }}</span
                >
              </div>

              <crm-history
                *ngIf="history$ && ticket"
                [history$]="history$"
                (openActivityEvent)="onOpenActivity($event)"
                (openTicketEvent)="onOpenTicket($any($event))"
              ></crm-history>
            </mat-accordion>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</crm-container>
