<nav class="breadcrumb">
  <ul>
    <li class="home hidden-sm hidden-xs">
      <a [routerLink]="['/']">
        <fa-icon [icon]="faHouse" size="sm" class="fa-light"></fa-icon>
      </a>
    </li>
    <li class="home-mobile hidden-md hidden-lg" *ngIf="breadcrumbs.length > 1">
      <a (click)="navigateToParent()">
        <fa-icon [icon]="faChevronLeft" size="1x" class="fa-light"></fa-icon>
      </a>
    </li>
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
      <li
        class="hidden-sm hidden-xs"
        *ngIf="i + 1 < breadcrumbs.length; else elseContent"
      >
        <fa-icon [icon]="faChevronRight" size="xs" class="fa-light"></fa-icon>
        <div>
          <a [routerLink]="[breadcrumb.url]">
            <span class="black">{{ breadcrumb.label | i18nextCap }}</span>
          </a>
        </div>
      </li>
      <ng-template #elseContent>
        <li>
          <fa-icon
            [icon]="faChevronRight"
            size="xs"
            class="fa-light hidden-sm hidden-xs"
          ></fa-icon>
          <div>
            <span class="black">{{ breadcrumb.label | i18nextCap }}</span>
          </div>
        </li>
      </ng-template>
    </ng-container>
  </ul>
</nav>
