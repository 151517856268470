<crm-breadcrumb
  [header]="title"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
  [maxSize]="'xl'"
></crm-breadcrumb>
<crm-container [maxSize]="'xl'">
  <div [@inOut]="'in'" class="content-wrapper" *ngIf="listPartsPagination$">
    <div class="table-container">
      <div class="table-container-header">
        <crm-parts-filters
          [filters$]="filters$"
          (filtersChangeEvent)="onFiltersChange($event)"
        ></crm-parts-filters>
      </div>
      <crm-table
        [paginatedList$]="listPartsPagination$"
        [displayedColumns]="displayedColumns"
        (onPageChange)="onPageChange($event)"
        (onClick)="onOpen($event)"
      >
        <!-- Name Column-->
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>
            {{ 'name' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="cell-row">
              <span class="title">{{ element.name }}</span>
              <span class="subtitle">{{ element.ref }}</span>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Price Column-->
        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef>
            {{ 'price' | i18nextCap }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="cell-row">
              <span class="title">{{ element.price }} €</span>
            </div>
          </mat-cell>
        </ng-container>
      </crm-table>
    </div>
  </div>
</crm-container>
