<mat-form-field class="d-block f-right" appearance="outline">
  <mat-label>{{ 'forms:' + field.label | i18nextCap }}</mat-label>
  <mat-chip-grid #chipList aria-label="Chips selection">
    <mat-chip
      *ngFor="let item of formGroup.controls[field.name].value"
      (removed)="remove(item)"
      class="category-chip"
    >
      <div class="category-chip__text">
        {{ field.translate ? (displayFn(item) | i18nextCap) : displayFn(item) }}
      </div>

      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      #chipsInput
      [placeholder]="
        field.placeholder
          ? ('translation:' + field.placeholder | i18nextCap)
          : ''
      "
      [formControl]="chipsAutocompleteCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <ng-container *ngFor="let item of filteredOptions | async">
      <mat-option [value]="item" *ngIf="!isNewValueAlreadyAdded(item.id)">
        <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
        <ng-container *ngIf="field.translate">
          {{ 'forms:' + item.name | i18nextCap }}
        </ng-container>
        <ng-container *ngIf="!field.translate">
          {{ item.name | titlecase }}
        </ng-container>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
