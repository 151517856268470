import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { UiDownloadsService } from '@fullyops/legacy/ui/ui-crm/ui-downloads.service';
import { switchMap } from 'rxjs/operators';
import { TokenizedAsset } from '@fullyops/legacy/data/api/types/TokenizedAsset';
import { TenantResolverService } from '@fullyops/core/services/tenant/tenant-resolver.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-download',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
})
export class DownloadsComponent implements OnInit {
  downloadForm: UntypedFormGroup;
  loading$ = new BehaviorSubject(false);
  tokenizedAsset$ = new ReplaySubject<HTMLAnchorElement>(1);
  returnUrl: string;
  token: string;
  tenantName: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private downloadsService: UiDownloadsService,
    private tenantResolverService: TenantResolverService
  ) {}

  ngOnInit() {
    this.downloadForm = this.formBuilder.group({});

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.token = this.route.snapshot.paramMap.get('token');
    this.tenantName = this.tenantResolverService.getActiveTenant();

    this.downloadsService
      .getAssetByToken(this.token, this.tenantName)
      .pipe(
        switchMap((value: TokenizedAsset) => {
          return this.downloadsService.downloadAssetByToken(
            this.token,
            value.filename,
            this.tenantName
          );
        })
      )
      .subscribe(this.tokenizedAsset$);

    this.downloadAsset();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.downloadForm.controls;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  downloadAsset(triggerBrowserDownload: boolean = false) {
    this.loading$.next(true);
    this.tokenizedAsset$.subscribe(
      (downloadLink: any) => {
        this.loading$.next(false);
        if (triggerBrowserDownload) {
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      },
      () => {
        this.loading$.next(false);
      }
    );
  }

  onSubmit() {
    this.downloadAsset(true);
  }
}
