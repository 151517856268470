<crm-breadcrumb
  [header]="title"
  [actionButtons]="actions"
  (actionEvent)="onActionEvent($event)"
  [maxSize]="'xl'"
></crm-breadcrumb>
<crm-container [maxSize]="'xl'">
  <ng-container *ngIf="productConfigs$ | async as productConfigs">
    <ng-container [ngSwitch]="productConfigs.state">
      <crm-error *ngSwitchCase="state.Error" [@inOut]="'in'"> </crm-error>
      <ng-container *ngSwitchCase="state.Loaded">
        <div [@inOut]="'in'" class="content-wrapper">
          <ng-container>
            <mat-accordion *ngIf="productConfigs.data.length">
              <crm-product-configuration-list
                *ngFor="let item of productConfigs.data"
                [listItem]="$any(item)"
                (openConfigurationEvent)="openEvent.emit($event)"
              ></crm-product-configuration-list>
            </mat-accordion>
            <crm-empty-warn
              *ngIf="!productConfigs.data.length"
              [message]="'noProductConfig'"
              [actionLabel]="'addNewProductConfig'"
              (actionEvent)="onAddNew()"
            ></crm-empty-warn>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</crm-container>
