<div mat-dialog-content>
  <div class="container">
    <h2 mat-dialog-title>{{ data.title | i18nextCap }}</h2>
    <mat-form-field
      class="example-chip-list full-width"
      appearance="outline"
      *ngIf="data.showCcList"
    >
      <mat-label>{{ 'translation:to' | i18nextCap }}</mat-label>
      <mat-chip-grid
        #ccEmailChipList
        aria-label="Email cc recipients selection"
      >
        <mat-chip
          *ngFor="let email of ccList"
          (removed)="remove(email, 'ccList')"
        >
          {{ email }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="email@example.com"
          #ccInput
          [formControl]="ccEmailControl"
          [matAutocomplete]="ccEmail"
          [matChipInputFor]="ccEmailChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add(ccInput, 'ccList')"
          (blur)="add(ccInput, 'ccList')"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #ccEmail="matAutocomplete"
        (optionSelected)="selected($event, 'ccList')"
      >
        <mat-option
          *ngFor="let email of filteredCCContactsEmail | async"
          [value]="email"
        >
          {{ email }}
        </mat-option>
      </mat-autocomplete>

      <mat-error *ngIf="ccEmailChipList.errorState">
        {{ ccEmailControl.errors?.message | i18nextCap }}
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="example-chip-list full-width"
      appearance="outline"
      *ngIf="data.showBccList"
    >
      <mat-label>BCC</mat-label>
      <mat-chip-grid
        #bccEmailChipList
        aria-label="Email Bcc recipients selection"
      >
        <mat-chip
          *ngFor="let email of bccList"
          (removed)="remove(email, 'bccList')"
        >
          {{ email }}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="email@example.com"
          #bccInput
          [formControl]="bccEmailControl"
          [matAutocomplete]="bccEmail"
          [matChipInputFor]="bccEmailChipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add(bccInput, 'bccList')"
          (blur)="add(bccInput, 'bccList')"
        />
      </mat-chip-grid>
      <mat-autocomplete
        #bccEmail="matAutocomplete"
        (optionSelected)="selected($event, 'bccList')"
      >
        <mat-option
          *ngFor="let email of filteredBCCContactsEmail | async"
          [value]="email"
        >
          {{ email }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <div class="button-container">
      <button
        mat-flat-button
        color="warn"
        class="red-button"
        (click)="onNoClick()"
      >
        {{ 'cancel' | i18nextCap }}
      </button>
      <div
        [matTooltip]="'translation:youAreNotAllowedToSendEmail' | i18nextCap"
        [matTooltipDisabled]="userCanSendEmail"
      >
        <button
          mat-flat-button
          color="primary"
          (click)="sendEmail()"
          type="submit"
          cdkFocusInitial
          [disabled]="!userCanSendEmail"
        >
          {{ 'reportListOkLabel' | i18nextCap }}
        </button>
      </div>
    </div>
  </div>
</div>
