import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FullCalendarComponent,
  FullCalendarModule,
} from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import pt from '@fullcalendar/core/locales/pt';
import enGb from '@fullcalendar/core/locales/en-gb';
import { CalendarOptions } from '@fullcalendar/core';
import { enumCheck } from '@fullyops/shared/util/enumCheck';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextModule, I18NextService } from 'angular-i18next';

@Component({
  selector: 'fo-calendar',
  standalone: true,
  templateUrl: './template.html',
  styleUrls: ['./style.scss'],
  imports: [FullCalendarModule, MaterialModule, I18NextModule],
})
export class CalendarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() items: CalendarItem[];
  @Input() focusedDate: string | null;
  @Output() onFocusedDateChange: EventEmitter<string> = new EventEmitter();
  @Input() view: CalendarView;
  @Output() onViewChange: EventEmitter<CalendarView> = new EventEmitter();
  @Output() onClickItem: EventEmitter<string> = new EventEmitter();
  @Output() onMoveItem: EventEmitter<CalendarItemDateChange> =
    new EventEmitter();

  protected options: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    events: [],
    height: '100%',
    dayCellClassNames: (cell) => {
      const d = cell.date.getDay();
      // Weekends are on Sunday and Saturday on all locales we support.
      if (d === 0 || d === 6) {
        return 'calendar-weekend-background';
      }
      return [];
    },
    eventClick: (info) => {
      this.onClickItem.next(info.event.id);
    },
    // Items in the same day are ordered the same way they were in the input
    // array. This ensures that any externally-applied sort is respected,
    // as far as possible.
    eventOrder: 'origIndex',
    datesSet: (info) => {
      const c = this.calendar.getApi();
      this.onFocusedDateChange.next(c.getDate().toISOString());
      this.onViewChange.next(viewMap[info.view.type]);
      switch (info.view.type) {
        case 'dayGridMonth': {
          const format = new Intl.DateTimeFormat(this.i18n.language, {
            year: 'numeric',
            month: 'long',
          });
          this.headerText = format.format(c.getDate());
          break;
        }
        case 'timeGridWeek': {
          const format = new Intl.DateTimeFormat(this.i18n.language, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
          this.headerText = format.formatRange(info.start, info.end);
          break;
        }
        case 'timeGridDay': {
          const format = new Intl.DateTimeFormat(this.i18n.language, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
          this.headerText = format.format(info.start);
          break;
        }
        default:
          this.headerText = '';
      }
      this.currentView = info.view.type;
      this.updateDayMaxEvents();
    },
    displayEventTime: false,
    handleWindowResize: false,
    locales: [pt, enGb],
    headerToolbar: false,
    dayMaxEvents: true,
    allDaySlot: false,
    moreLinkClick: 'day',
    moreLinkClassNames: 'calendar-more-link',
    moreLinkContent: (props) => `+${props.num}...`,

    editable: true,
    eventDrop: (dropInfo) => {
      if (dropInfo.oldEvent.allDay !== dropInfo.event.allDay) {
        dropInfo.revert();
        return;
      }
      const itemId = dropInfo.event.id;
      if (dropInfo.event.allDay) {
        this.onMoveItem.next({
          itemId,
          newWhen: { kind: 'allDay', date: dropInfo.event.start.toISOString() },
        });
      } else if (dropInfo.event.end == null) {
        this.onMoveItem.next({
          itemId,
          newWhen: { kind: 'point', date: dropInfo.event.start.toISOString() },
        });
      } else {
        this.onMoveItem.next({
          itemId,
          newWhen: {
            kind: 'interval',
            start: dropInfo.event.start.toISOString(),
            end: dropInfo.event.end.toISOString(),
          },
        });
      }
    },
    eventResize: (dropInfo) => {
      this.onMoveItem.next({
        itemId: dropInfo.event.id,
        newWhen: {
          kind: 'interval',
          start: dropInfo.event.start.toISOString(),
          end: dropInfo.event.end.toISOString(),
        },
      });
    },
  };

  protected headerText = '';
  protected currentView = '';

  protected switchView(view: string) {
    this.calendar.getApi().changeView(view);
  }

  protected fastBack() {
    this.calendar.getApi().prevYear();
  }

  protected back() {
    this.calendar.getApi().prev();
  }

  protected gotoToday() {
    this.calendar.getApi().today();
  }

  protected forward() {
    this.calendar.getApi().next();
  }

  protected fastForward() {
    this.calendar.getApi().nextYear();
  }

  private miniMode = false;

  private updateDayMaxEvents() {
    switch (this.currentView) {
      case 'dayGridMonth':
        this.options.dayMaxEvents = this.miniMode ? 0 : true;
        break;
      case 'timeGridWeek':
        this.options.dayMaxEvents = this.miniMode ? 0 : true;
        break;
      default:
        // In day view, let the user scroll when there are lots of events in one day.
        this.options.dayMaxEvents = false;
        break;
    }
  }

  // FullCalendar does not automatically reflow when its container changes size;
  // this must be done manually by calling its updateSize method.
  // We use a ResizeObserver to arrange for this to happen automatically.
  constructor(private hostElement: ElementRef, private i18n: I18NextService) {
    this.observer = new ResizeObserver(([container, ..._]) => {
      const containerWidth = container.contentBoxSize[0].inlineSize;
      this.miniMode = containerWidth < miniCalendarThresholdPx;
      this.updateDayMaxEvents();
      this.calendar.getApi().updateSize();
    });
  }

  private observer: ResizeObserver;

  @ViewChild(FullCalendarComponent, { static: true })
  private calendar: FullCalendarComponent;

  ngOnInit() {
    this.observer.observe(this.hostElement.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items != null) {
      this.options.events = (changes.items.currentValue as CalendarItem[]).map(
        (it, i) => ({
          id: it.id,
          title: it.name,
          className: 'clickable-calendar-item',
          color: it.color,
          allDay: it.when.kind === 'allDay',
          start: it.when.kind === 'interval' ? it.when.start : it.when.date,
          end: it.when.kind === 'interval' ? it.when.end : null,
          extendedProps: { origIndex: i },
        })
      );
    }
    if (
      changes.focusedDate != null &&
      changes.focusedDate.currentValue != null
    ) {
      const api = this.calendar.getApi();
      if (api == null) {
        this.options.initialDate = changes.focusedDate.currentValue;
      } else {
        api.gotoDate(changes.focusedDate.currentValue);
      }
    }
    if (changes.view != null) {
      const api = this.calendar.getApi();
      const targetView = reverseViewMap[changes.view.currentValue];
      if (api == null) {
        this.options.initialView = targetView;
      } else {
        api.changeView(targetView);
      }
    }
  }
}

const miniCalendarThresholdPx = 700;

const viewMap = {
  dayGridMonth: 'month',
  timeGridWeek: 'week',
  timeGridDay: 'day',
  dayGridWeek: 'week',
  dayGridDay: 'day',
} as const;

const reverseViewMap = {
  month: 'dayGridMonth',
  week: 'timeGridWeek',
  day: 'timeGridDay',
};

const calendarViews = ['month', 'week', 'day'] as const;
export type CalendarView = typeof calendarViews[number];
export const parseCalendarView = enumCheck(calendarViews);

export interface CalendarItem {
  id: string;
  name: string;
  color?: string;
  when: CalendarItemDate;
}

export interface CalendarItemDateChange {
  itemId: string;
  newWhen: CalendarItemDate;
}

export type CalendarItemDate = PointDate | DateInterval;

export interface PointDate {
  kind: 'allDay' | 'point';
  date: string;
}

export interface DateInterval {
  kind: 'interval';
  start: string;
  end: string;
}
