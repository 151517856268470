<section [formGroup]="subsectionForm">
  <header>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'forms:subsectionName' | i18nextCap }}</mat-label>
      <input matInput formControlName="title" />
    </mat-form-field>
    <span class="spacer"></span>
    <button
      *ngIf="!preview"
      mat-button
      color="warn"
      type="button"
      (click)="removeSubsection()"
    >
      {{ 'forms:removeSubsection' | i18nextCap }}
    </button>
    <button
      *ngIf="!preview"
      mat-button
      color="primary"
      type="button"
      (click)="addSubsectionItem()"
    >
      {{ 'forms:addSubsectionItem' | i18nextCap }}
    </button>
  </header>

  <div class="row">
    <div class="col-12">
      <crm-empty-warn
        *ngIf="!content.controls.length"
        [message]="'subsectionItemsListIsEmpty'"
        [actionLabel]="'addSubsectionItem'"
        (actionEvent)="addSubsectionItem()"
      ></crm-empty-warn>
    </div>
    <div
      class="col-xl-3 col-md-4 col-sm-6"
      *ngFor="let row of content.controls; let i = index"
    >
      <mat-card>
        <mat-card-header>
          <mat-card-title>Item {{ i + 1 }}</mat-card-title>
          <mat-card-subtitle
            >{{ 'forms:subsection' | i18nextCap }} -
            {{ subsectionForm.value.title }}</mat-card-subtitle
          >
          <button
            *ngIf="!preview"
            mat-icon-button
            type="button"
            (click)="removeSubsectionItem(i)"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content>
          <crm-product-form-details-subsection-item
            [preview]="preview"
            [subsectionItemForm]="$any(row)"
            [uploadService]="uploadService"
          ></crm-product-form-details-subsection-item>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>
