<div class="signature-pad">
  <div class="signature-pad--body">
    <canvas #sPad style="touch-action: none"></canvas>
  </div>
  <div class="action-buttons">
    <button mat-flat-button type="button" color="secondary" (click)="clear()">
      {{ 'forms:clear' | i18nextCap }}
    </button>
  </div>
</div>
