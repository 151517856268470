import { Inject, Injectable } from '@angular/core';
import { UiServiceWithCache } from './ui.service-with-cache.service';
import { API_SERVICE_CONFIG } from '../../data/api/ApiServiceConfig';
import { IApiServiceConfig } from '../../data/api/IApiServiceConfig';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AnomalyRequest, AnomalyResponse } from '../../data/api/types/Anomaly';
import { SelectOptionType } from '../ui-shared/form-select/form-select.component';
import * as moment from 'moment';
import { PageResponseWrapper } from '../../data/api/types/HttpResponses';
import { FormControl } from '@angular/forms';

type QueryParameters = {
  createdBy?: string;
  description?: string;
  includeDeleted?: boolean;
  order?: 'ASC' | 'DESC';
  orderBy?: 'CREATED_AT' | 'UPDATED_AT';
  page?: number;
  size?: number;
  typeId?: string;
  updatedBy?: string;
};

type GetAllParams = {
  queryParameters?: QueryParameters;
  ignoreCacheTime?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AnomalyController extends UiServiceWithCache {
  constructor(
    @Inject(API_SERVICE_CONFIG) private config: IApiServiceConfig,
    private http: HttpClient
  ) {
    super();
    this.updateAnomaliesSelectOptions();
  }

  protected cacheData = {
    anomalies$: new BehaviorSubject<AnomalyResponse[]>([]),
    anomaliesSelectOptions$: new BehaviorSubject<SelectOptionType[]>([]),
  };

  private readonly url = this.config.url + 'anomalies';

  protected getAll({ queryParameters, ignoreCacheTime }: GetAllParams) {
    if (!this.isCacheValid() || ignoreCacheTime) {
      let query = '';
      if (queryParameters) {
        const queries = this.createQueryParams(queryParameters);
        query = `?${queries}`;
      }

      const response = this.http.get<PageResponseWrapper<AnomalyResponse>>(
        `${this.url}${query}`
      );
      response.subscribe((e) => {
        this.cacheData.anomalies$.next(e.results);
        this.cacheTime = moment();
      });
    }
  }

  protected updateAnomaliesSelectOptions() {
    this.cacheData.anomalies$.subscribe((e) => {
      const data = e.map(({ id, description }) => ({ id, name: description }));
      this.cacheData.anomaliesSelectOptions$.next(data);
    });
  }

  protected async create(anomalyRequest: AnomalyRequest) {
    try {
      const anomalyCreated = await this.http
        .post<AnomalyResponse>(this.url, anomalyRequest)
        .toPromise();
      const newValue = this.cacheData.anomalies$.value;
      newValue.push(anomalyCreated);
      this.cacheData.anomalies$.next(newValue);
      this.cacheTime = moment();
      return anomalyCreated;
    } catch {}
  }

  getAnomaliesSelectOptions$() {
    this.getAll({});
    return this.cacheData.anomaliesSelectOptions$;
  }

  getAnomalies$() {
    this.getAll({});
    return this.cacheData.anomalies$;
  }

  refetchByName({ description }: { description: string }) {
    this.getAll({ ignoreCacheTime: true, queryParameters: { description } });
  }

  async addAnomalyOnForm({
    description,
    formControl,
    inputElement,
    chipsAutocompleteCtrl,
  }: {
    description: string;
    formControl: FormControl;
    inputElement: HTMLInputElement;
    chipsAutocompleteCtrl: FormControl;
  }) {
    const resetAutocompleteInput = () => {
      inputElement.value = '';
      chipsAutocompleteCtrl.setValue(null);
    };

    const addDataOnForm = (anomaly: SelectOptionType) => {
      const newValue = [...formControl.value];
      newValue.push(anomaly);
      formControl.setValue(newValue);
      resetAutocompleteInput();
    };

    const anomalyFound = this.findExactlyAnomalyOption(description);
    if (anomalyFound) {
      addDataOnForm(anomalyFound);
    } else {
      const anomalyCreated = await this.create({ description });
      const anomalyCreatedOption = this.findExactlyAnomalyOption(
        anomalyCreated.description
      );
      addDataOnForm(anomalyCreatedOption);
    }
  }

  findExactlyAnomalyOption(description: string) {
    return this.cacheData.anomaliesSelectOptions$.value.find((anomaly) => {
      return anomaly.name === description;
    });
  }
}
