import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'crm-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent implements OnInit {
  @Input() title: string = '';
  @Input() icon: string;
  @Input() isExpanded: boolean = true;

  ngOnInit() {}
}
