import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ControlsOf } from '@fullyops/shared/util/controls-of';
import { WorkOrderCategoryModel } from '@fullyops/domain/work-order-categories/models/work-order-category.model';
import { WorkOrderCategoriesService } from '@fullyops/domain/work-order-categories/services/work-order-categories.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'create-work-order-categories',
  templateUrl: './edit-work-order-categories.component.html',
  styleUrls: ['./edit-work-order-categories.component.scss'],
})
export class EditWorkOrderCategoriesComponent {
  @Output() workOrderCategoryEdited$ = new EventEmitter<void>();

  protected id: string;
  protected formGroup: FormGroup<
    ControlsOf<Omit<WorkOrderCategoryModel, 'id' | 'deleted'>>
  >;
  constructor(
    private service: WorkOrderCategoriesService,
    @Inject(MAT_DIALOG_DATA) public data: WorkOrderCategoryModel
  ) {
    this.id = data.id;
    this.formGroup = new FormGroup<
      ControlsOf<Omit<WorkOrderCategoryModel, 'id' | 'deleted'>>
    >({
      label: new FormControl<string>(data.label, [
        Validators.required,
        this.duplicateCategoryLabelValidator(),
      ]),
      name: new FormControl<string>(data.name, [
        Validators.required,
        this.duplicateCategoryNameValidator(),
      ]),
      color: new FormControl<string | null>(data.color, [
        Validators.pattern('^#[a-f0-9]{6}'),
      ]),
      externalId: new FormControl<string | null>(data.externalId, [
        this.duplicateCategoryExternalIdValidator(),
      ]),
    });
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const formData = this.formGroup.getRawValue();
      this.service
        .update({
          ...formData,
          id: this.id,
          deleted: false,
        })
        .subscribe({
          complete: () => this.workOrderCategoryEdited$.emit(),
        });
    }
  }

  onCancel() {}

  private duplicateCategoryLabelValidator(): ValidatorFn {
    const id = this.id;
    return (control: AbstractControl): ValidationErrors | null => {
      const categoryLabel = control.value;
      const category = this.service.getByLabel(categoryLabel);
      if (!category) {
        return null;
      }

      const categoryExists = category?.id != id;
      return categoryExists
        ? { duplicateCategory: { value: categoryLabel } }
        : null;
    };
  }

  private duplicateCategoryNameValidator(): ValidatorFn {
    const id = this.id;
    return (control: AbstractControl): ValidationErrors | null => {
      const categoryName = control.value;
      const category = this.service.getByName(categoryName);
      if (!category) {
        return null;
      }

      const categoryExists = category?.id != id;

      return categoryExists
        ? { duplicateCategory: { value: categoryName } }
        : null;
    };
  }

  private duplicateCategoryExternalIdValidator(): ValidatorFn {
    const id = this.id;
    return (control: AbstractControl): ValidationErrors | null => {
      const categoryExternalId = control.value;
      if (categoryExternalId == null) {
        return null;
      }

      const category = this.service.getByExternalId(categoryExternalId);
      if (!category) {
        return null;
      }

      const categoryExists = category?.id != id;

      return categoryExists
        ? { duplicateCategory: { value: categoryExternalId } }
        : null;
    };
  }

  protected readonly externalIdToolTipText = 'categoryExternalIdToolTip';
}
