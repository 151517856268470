<section>
  <div class="filters">
    <button mat-icon-button [matMenuTriggerFor]="matMenu">
      <mat-icon>tune</mat-icon> {{ 'translation:filters' | i18nextCap }}
    </button>
  </div>
  <mat-chip-listbox
    #chipList
    aria-label="filters"
    class="selected-filters"
    *ngIf="currentFilters$ | async as filters"
  >
    <mat-chip
      *ngFor="let filter of filters | keyvalue"
      [removable]="true"
      (removed)="removeFilter($any(filter).key)"
    >
      <b>{{ filter.key | i18nextCap }}: </b>{{ filter.value | i18nextCap }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-listbox>
</section>

<mat-menu #matMenu="matMenu">
  <div class="filters-menu">
    <label>{{ 'translation:filters' | i18nextCap }}</label>
    <crm-form-template
      [formGroup]="filtersForm"
      [formFields]="filtersFormFields"
      (click)="$event.stopPropagation()"
      class="padding-crm-form-template"
    ></crm-form-template>
    <button mat-flat-button color="primary" (click)="applyFilters()">
      <mat-icon>filter_alt</mat-icon>
      <span>{{ 'translation:applyFilters' | i18nextCap }}</span>
    </button>
  </div>
</mat-menu>
