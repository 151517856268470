import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'crm-sidenav-form-container',
  templateUrl: './sidenav-form-container.component.html',
  styleUrls: ['./sidenav-form-container.component.scss'],
})
export class SidenavFormContainerComponent {
  @Input() title: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() formFields: any[];
  @Input() isDeletable = false;
  @Output() onSubmit = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  cancelForm() {
    this.onCancel.emit();
  }

  submitForm() {
    this.onSubmit.emit();
  }

  deleteForm() {
    this.onDelete.emit();
  }
}
