import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import {
  NEW_TRAVEL_TOKEN,
  NewTravelFormComponent,
} from './new-travel-form/new-travel-form.component';
import {
  TravelRequest,
  TravelResponse,
} from '@fullyops/legacy/data/api/types/Travel';
import { TravelControllerV2 } from '@fullyops/legacy/ui/ui-crm/ui-travel-controller-v2.service';
import { TravelAssigneeControllerV2 } from '@fullyops/legacy/ui/ui-crm/ui-travel-assignee-controller-vs.service';
import { DialogService } from '@fullyops/legacy/ui/ui-shared/dialog/dialog.service';
import { UserResponse } from '@fullyops/legacy/data/api/types/User';
import { SupportTicketResponse } from '@fullyops/legacy/data/api/types/SupportTicket';
import { User } from '@fullyops/legacy/data';
import { convertMiliSecondsToTimeString } from '@fullyops/legacy/ui/ui-shared/utils/date-transformers';
import { TimeZoneDateFormatterService } from '@fullyops/core/services/date-formatter.service';
import { SidenavPortalBridgeService } from '@fullyops/core/sidenav-form-portal-bridge.service';

type AssigneeTravelData = {
  id: string;
  firstName: string;
  lastName: string;
  distance: number;
  stayedDays: number;
  total: number;
};

type TravelHistory = {
  assigneesTravels: AssigneeTravelData[];
  totalStays: number;
  totalDistance: number;
  totalHours: number;
};

export type TravelData = {
  travel: TravelRequest;
  assigneesIds: string[];
};

export type TravelDataToEdit = {
  travel: TravelRequest;
  assigneesIds: string[];
};

@Component({
  selector: 'crm-travels',
  templateUrl: './travels.component.html',
  styleUrls: ['./travels.component.scss'],
})
export class TravelsComponent implements OnInit {
  constructor(
    protected travelControllerV2: TravelControllerV2,
    protected travelAssigneeControllerV2: TravelAssigneeControllerV2,
    private dialogService: DialogService,
    public timezoneDate: TimeZoneDateFormatterService,
    private sidenavPortalBridgeService: SidenavPortalBridgeService
  ) {}
  @Input() usersAssignees$: BehaviorSubject<UserResponse[]>;
  @Input() ticket$: BehaviorSubject<SupportTicketResponse>;

  travelHistory$ = new BehaviorSubject<TravelHistory>(null);

  ngOnInit() {
    this.parseAssigneesTravelHours();
  }

  parseAssignees(travel: TravelResponse): User[] {
    return travel.assignees.map(
      (assignee) => assignee.assignee as unknown as User
    );
  }

  formatTimeToString(dateString: string) {
    return this.timezoneDate.formatTimeString(dateString);
  }

  formatTimeDiffToString(startingHour: string, endingHour: string) {
    const travelDiff = this.timezoneDate.getTimeDiff(startingHour, endingHour);
    return convertMiliSecondsToTimeString(travelDiff);
  }

  parseToTimeString(milliseconds) {
    return convertMiliSecondsToTimeString(milliseconds);
  }

  openForm(travel?: TravelResponse) {
    this.sidenavPortalBridgeService.openPortal(
      NewTravelFormComponent,
      NEW_TRAVEL_TOKEN,
      {
        travel,
        defaultDistance: this.ticket$.value.company.distance || 0,
        defaultTravelTime: this.ticket$.value.company.travelTime || 0,
        assignees$: this.usersAssignees$,
        onSave: !!travel ? this.editTravel : this.createTravel,
        onCancel: this.closeForm,
        onDelete: !!travel ? () => this.removeTravel(travel.id) : null,
      }
    );
  }

  closeForm = () => {
    this.sidenavPortalBridgeService.closePortal();
  };

  parseAssigneesTravelHours() {
    this.ticket$.subscribe((ticket) => {
      const travelHistory: TravelHistory = ticket.travels.reduce<TravelHistory>(
        (acc: TravelHistory, travel) => {
          travel.assignees.forEach((item) => {
            const assigneeWork = acc.assigneesTravels.find(
              (a) => a.id === item.assignee.id
            );
            const travelTime = this.timezoneDate.getTimeDiff(
              travel.startingHour,
              travel.endingHour
            );

            if (!assigneeWork) {
              const assigneeData = {
                id: item.assignee.id,
                firstName: item.assignee.firstName,
                lastName: item.assignee.lastName,
                distance: travel.distance,
                stayedDays: travel.stay ? 1 : 0,
                total: travelTime,
              };
              acc.assigneesTravels.push(assigneeData);
            } else {
              assigneeWork.distance += travel.distance;
              assigneeWork.stayedDays += travel.stay ? 1 : 0;
              assigneeWork.total += travelTime;
            }
            acc.totalStays += travel.stay ? 1 : 0;
            acc.totalDistance += travel.distance;
            acc.totalHours += travelTime;
          });

          return acc;
        },
        { assigneesTravels: [], totalStays: 0, totalDistance: 0, totalHours: 0 }
      );

      this.travelHistory$.next(travelHistory);
    });
  }

  reloadTravels(props: { travelIdToOpenAfterLoad?: string }) {
    this.travelControllerV2
      .getAll({
        queryParameters: {
          order: 'ASC',
          orderBy: 'STARTING_HOUR',
          supportTicketId: this.ticket$.value.id,
        },
      })
      .subscribe((res) => {
        const newTicket = { ...this.ticket$.value };
        newTicket.travels = res.results;
        this.ticket$.next(newTicket);
      });
  }

  removeTravel(travelId: string) {
    const dialogRef = this.dialogService.openDialogBeforeDelete();

    dialogRef.afterClosed().subscribe((saveOutput) => {
      if (saveOutput) {
        this.travelControllerV2.delete({ travelId }).subscribe(() => {
          const newTicket = { ...this.ticket$.value };
          newTicket.travels = this.ticket$.value.travels.filter(
            (travel) => travel.id !== travelId
          );
          this.ticket$.next(newTicket);
          this.closeForm();
        });
      }
    });
  }

  createTravel = (data: TravelData) => {
    const supportTicketId = this.ticket$.value.id;

    const assignUsersRequests = (travelId: string) => {
      return data.assigneesIds.map((assigneeId) => {
        return this.travelAssigneeControllerV2.assignUserToTravel({
          assigneeId,
          travelId,
        });
      });
    };

    this.travelControllerV2
      .create({ travel: { ...data.travel, supportTicketId } })
      .subscribe((travelResponse) => {
        forkJoin([...assignUsersRequests(travelResponse.id)]).subscribe(() => {
          this.reloadTravels({});
          this.closeForm();
        });
      });
  };

  editTravel = (data: TravelDataToEdit) => {
    const oldTravel = this.ticket$.value.travels.find(
      (t) => t.id === data.travel.id
    );
    const assignUsersRequests = () => {
      const oldAssignees = oldTravel.assignees;
      const newAssignees = data.assigneesIds;

      const assigneesToAdd = newAssignees.filter((newAssignee) => {
        return !oldAssignees.some(
          (oldAssignee) => oldAssignee.assignee.id == newAssignee
        );
      });

      const assigneesToRemove = oldAssignees.filter((assignee) => {
        return !newAssignees.some(
          (newAssigneeId) => assignee.assignee.id == newAssigneeId
        );
      });

      const assignUsersRequests = assigneesToAdd.map((assigneeId) => {
        return this.travelAssigneeControllerV2.assignUserToTravel({
          travelId: oldTravel.id,
          assigneeId,
        });
      });

      const unAssignUsersRequests = assigneesToRemove.map(
        (assigneeResponse) => {
          return this.travelAssigneeControllerV2.unAssignUserToTravel({
            assigneeId: assigneeResponse.assignee.id,
            travelId: oldTravel.id,
          });
        }
      );

      if (
        assignUsersRequests.length == 0 &&
        unAssignUsersRequests.length == 0
      ) {
        return of({});
      }

      return forkJoin([...assignUsersRequests, ...unAssignUsersRequests]);
    };

    const updateBasicData = () => {
      return this.travelControllerV2.updateById({
        travel: data.travel,
        travelId: oldTravel.id,
      });
    };

    assignUsersRequests().subscribe(() => {
      updateBasicData().subscribe((travelResponse) => {
        this.reloadTravels({});
        this.closeForm();
      });
    });
  };
}
