import {
  FieldTypes,
  TemplateFormFields,
} from '@fullyops/legacy/ui/ui-shared/form-template/form-template.component';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Company, Status } from '@fullyops/legacy/data';
import { BehaviorSubject, take } from 'rxjs';
import { ComponentInOutAnimation } from '@fullyops/legacy/ui/ui-shared/utils/component-base-animation';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  CustomFilter,
  Priority,
} from '@fullyops/legacy/ui/ui-shared/utils/crm-types';
import { UiAuthService } from '../ui-auth.service';
import { isArray } from 'lodash';
import { I18NextPipe } from 'angular-i18next';

type FormFilters = {
  identifier?: string;
  name?: string;
  priority?: Priority;
  companyIds?: Company[];
  userIds?: string[];
  statusIds?: Status[];
  orderBy?:
    | 'COMPANY'
    | 'STATUS'
    | 'STARTS_AT'
    | 'ENDS_AT'
    | 'NAME'
    | 'IDENTIFIER';
  order?: 'ASC' | 'DESC';
};

type CurrentFilters = {
  identifier?: string;
  name?: string;
  priority?: Priority;
  companyIds?: string[];
  userIds?: string[];
  orderBy?:
    | 'COMPANY'
    | 'STATUS'
    | 'STARTS_AT'
    | 'ENDS_AT'
    | 'NAME'
    | 'IDENTIFIER';
  order?: 'ASC' | 'DESC';
};

@Component({
  selector: 'crm-pipeline-filters',
  templateUrl: './pipeline-filters.component.html',
  styleUrls: ['./pipeline-filters.component.scss'],
  animations: [ComponentInOutAnimation.AnimTrigger],
})
export class PipelineFiltersComponent implements OnInit {
  constructor(
    protected authService: UiAuthService,
    public i18nextPipe: I18NextPipe
  ) {}
  @Input() page: string;
  @Input() selfPipeline = false;
  @Input() customFilterFields: CustomFilter[] = [];
  @Input() filters$: BehaviorSubject<{}>;
  @Input() companies: Company[] = [];
  @Input() statuses$: BehaviorSubject<Status[]>;
  @Input() usersOptions$: BehaviorSubject<{ id: string; name: string }[]>;
  @Output() filtersChangeEvent = new EventEmitter<{}>();

  filtersForm: UntypedFormGroup;
  filtersFormFields: TemplateFormFields<any> = [];
  currentFilters$ = new BehaviorSubject<{}>({});

  clients$ = new BehaviorSubject<any[]>(null);
  statusFilters$ = new BehaviorSubject<any[]>([]);

  orderItems$ = new BehaviorSubject<any[]>([]);
  orderByItems$ = new BehaviorSubject<any[]>([]);
  order = {
    name: 'order',
    type: 'select',
    label: 'order',
    size: 6,
    items$: this.orderItems$,
    nullable: false,
    translate: true,
  };
  orderBy = {
    name: 'orderBy',
    type: 'select',
    label: 'orderBy',
    size: 12,
    items$: this.orderByItems$,
    nullable: false,
    translate: true,
  };

  orderByFields = ['COMPANY', 'STATUS', 'STARTS_AT', 'ENDS_AT', 'NAME'];

  ngOnInit() {
    this.initFormGroup();
    this.initFormFields();

    this.usersOptions$.subscribe((e) => {
      this.updateChipsFilters();
    });
    this.initStatusOptions();
  }

  initStatusOptions() {
    this.statuses$.subscribe((status) => {
      if (status !== null) {
        this.statusFilters$.next(
          status.map((status) => {
            return {
              id: status,
              name: status.name,
            };
          })
        );
      }
    });
  }

  initFormGroup() {
    this.filtersForm = new UntypedFormGroup({
      identifier: new UntypedFormControl('', []),
      name: new UntypedFormControl('', []),
      priority: new UntypedFormControl('', []),
      companyIds: new UntypedFormControl([], []),
      userIds: new UntypedFormControl([], []),
      statusIds: new UntypedFormControl([], []),
      companyNIF: new UntypedFormControl(null, []),
      orderBy: new UntypedFormControl('STARTS_AT', []),
      order: new UntypedFormControl('ASC', []),
    });

    if (this.page == 'workOrder') {
      this.filtersForm.addControl('categories', new FormControl([]));
      this.filtersForm.addControl('scopes', new FormControl(''));
      this.filtersForm.addControl('interventionTypes', new FormControl([]));
    }

    this.filters$.pipe(take(1)).subscribe((filters: CurrentFilters) => {
      Object.entries(filters).forEach(([key, value]) => {
        if (['startsAt', 'endsAt', 'finalizedIn'].includes(key))
          return this.filtersForm
            .get(key)
            .patchValue(new Date(value as string));

        if (key === 'companyIds') {
          const companies = (value as string[]).map((companyId) => {
            return this.companies.find((comp) => comp.id == companyId);
          });
          return this.filtersForm.get(key).patchValue(companies);
        }

        if (key === 'statusIds') {
          return this.statuses$.subscribe(() => {
            const statuses = (value as string[]).map((sId) => {
              return this.statuses$.value?.find((s) => s.id == sId);
            });
            this.filtersForm.get(key).patchValue(statuses);
            return this.updateChipsFilters();
          });
        }

        if (this.filtersForm.get(key)) {
          this.filtersForm.get(key).patchValue(value);
        }
      });

      this.updateChipsFilters();
    });
  }

  initFormFields() {
    this.orderItems$.next(
      ['ASC', 'DESC'].map((value) => ({
        id: value,
        name: value.toLowerCase(),
      }))
    );

    this.orderByItems$.next(
      ['COMPANY', 'STATUS', 'STARTS_AT', 'ENDS_AT', 'NAME', 'IDENTIFIER'].map(
        (value) => ({
          id: value,
          name: value.toLowerCase(),
        })
      )
    );

    this.clients$.next(
      this.companies.map((company: Company) => ({
        id: company,
        name: company.name,
      }))
    );

    const priorities$ = new BehaviorSubject(
      Object.keys(Priority).map((key) => ({
        id: key,
        name: Priority[key],
      }))
    );

    this.filtersFormFields = [
      {
        name: 'identifier',
        type: 'text',
        label: 'identifier',
        size: 12,
      },
      {
        name: 'name',
        type: 'text',
        label: 'name',
        size: 12,
      },
      {
        name: 'priority',
        type: 'select',
        label: 'priority',
        size: 12,
        items$: priorities$,
        nullable: false,
        translate: true,
        dataTestId: 'filter-priority',
      },
      {
        name: 'userIds',
        type: 'multiple-select',
        label: 'assignees',
        size: 12,
        items$: this.usersOptions$,
        nullable: false,
        translate: false,
      },
      {
        name: 'statusIds',
        type: 'multiple-select',
        label: 'status',
        size: 12,
        items$: this.statusFilters$,
        nullable: false,
        translate: true,
      },
      {
        name: 'companyIds',
        type: 'chips-autocomplete',
        label: 'companies',
        size: 12,
        items$: this.clients$,
        nullable: false,
        translate: false,
      },
      {
        name: 'companyNIF',
        type: 'text',
        label: 'nif',
        size: 12,
      },
    ];

    this.customFilterFields.forEach((customFilter: CustomFilter) => {
      this.filtersForm.addControl(
        customFilter.name,
        new UntypedFormControl('', [])
      );

      if (!!customFilter.orderBy) {
        this.orderByFields.push(customFilter.orderBy);
      }

      //@ts-ignore
      this.filtersFormFields.push({
        name: customFilter.name,
        type: customFilter.type as FieldTypes,
        label: customFilter.name,
        size: 12,
      });
    });

    this.orderByItems$.next(
      this.orderByFields.map((value) => ({
        id: value,
        name: value.toLowerCase(),
      }))
    );
  }

  getCompanyNameById(companyId) {
    const companyFound = this.companies.find(
      (company) => company.id == companyId
    );
    return companyFound ? companyFound.name : '';
  }

  getFiltersFormattedToRequest() {
    return Object.entries(this.filtersForm.value).reduce(
      (acc, [key, value]) => {
        if (value === undefined || value === null || value === '') return acc;

        if (Array.isArray(value) && value.length == 0) {
          return acc;
        }

        if (value instanceof Date) {
          acc[key] = value.toISOString();
          return acc;
        }

        switch (key) {
          case 'companyIds':
            acc['companyIds'] = (value as Company[]).map((u) => u.id);
            break;
          case 'statusIds':
            acc['statusIds'] = (value as Status[]).map((s) => s.id);
            break;
          case 'identifier':
            acc['identifier'] = (value as string).replace(/\D/g, '');
            break;
          default:
            acc[key] = value;
            break;
        }
        return acc;
      },
      {}
    );
  }

  translate(text) {
    return this.i18nextPipe.transform(text);
  }

  concatMultipleItems(list: { name: string }[], maxItems = 2) {
    let temp = '';
    list = list.filter((item) => item !== null && item !== undefined);
    if (list.length == 0) return temp;
    if (list.length >= maxItems) {
      temp = `${this.translate(list[0].name)}, ${this.translate(list[1].name)}`;

      if (list.length > maxItems) temp = `${temp}, +${list.length - maxItems}`;
    } else {
      temp = `${this.translate(list[0].name)}`;
    }
    return temp;
  }

  updateChipsFilters() {
    const filters: FormFilters = this.filtersForm.value;
    let currentFilters = {};

    Object.keys(filters).forEach((key: keyof FormFilters) => {
      if (
        filters[key] === undefined ||
        filters[key] === null ||
        filters[key] === ''
      )
        return;

      if (key === 'order' || key === 'orderBy') return;

      const value = filters[key];

      if (Array.isArray(value)) {
        if (value.length == 0) return;
      }

      if (value instanceof Date) {
        return (currentFilters[key] = value.toLocaleDateString());
      }

      if (key == 'userIds') {
        return (currentFilters['userIds'] = this.concatMultipleItems(
          filters[key].map((optionValue) => {
            return this.usersOptions$.value.find(({ id }) => optionValue == id);
          })
        ));
      }

      if (key == 'companyIds') {
        return (currentFilters['companyIds'] = this.concatMultipleItems(
          filters[key]
        ));
      }

      if (key == 'statusIds') {
        return (currentFilters['statusIds'] = this.concatMultipleItems(
          filters[key]
        ));
      }

      currentFilters[key] = filters[key];
    });
    this.currentFilters$.next(currentFilters);
  }

  applyFilters() {
    this.filtersChangeEvent.emit(this.getFiltersFormattedToRequest());
    this.updateChipsFilters();
  }

  removeFilter(filter: keyof CurrentFilters | keyof FormFilters) {
    this.filtersForm.get(filter).patchValue(null);
    this.applyFilters();
  }

  getText(value) {
    if (typeof value == 'string') return value;
    if (isArray(value)) {
      return this.concatMultipleItems(value);
    }
    if (value.name) return value.name;
    else return 'error';
  }

  get activeFilters() {
    return Object.entries(this.currentFilters$.value).reduce(
      (acc, [key, value]) => {
        if (key !== 'order' && key !== 'orderBy') return acc + 1;
        else return acc;
      },
      0
    );
  }
}
