<crm-expansion-panel title="{{ 'forms:workingRegistry' | i18nextCap }}">
  <mat-tab-group
    mat-align-tabs="center"
    [selectedIndex]="selected.value"
    (selectedIndexChange)="selected.setValue($event)"
    class="remove-mat-tab-group-margins"
  >
    <!-- Trips -->
    <mat-tab [attr.data-testid]="'working-period-trips'">
      <ng-template mat-tab-label
        ><mat-icon>local_shipping</mat-icon>
        {{ 'forms:trips' | i18nextCap }}</ng-template
      >
      <div class="tab-content">
        <crm-travels
          [ticket$]="ticket$"
          [usersAssignees$]="assignees$"
        ></crm-travels>
      </div>
    </mat-tab>
    <mat-tab [attr.data-testid]="'working-period-hours'">
      <ng-template mat-tab-label
        ><mat-icon>work_history</mat-icon>
        {{ 'forms:hours' | i18nextCap }}</ng-template
      >
      <app-working-period
        [ticket$]="ticket$"
        [usersAssignees$]="assignees$"
      ></app-working-period>
    </mat-tab>
  </mat-tab-group>
</crm-expansion-panel>
