import { CommonModule } from '@angular/common';
import {
  Component,
  ContentChildren,
  Directive,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFilter, faXmark } from '@fortawesome/free-solid-svg-icons';
import { MaterialModule } from '@fullyops/shared/material.module';
import { I18NextModule } from 'angular-i18next';
import { Observable } from 'rxjs';

@Directive({ selector: '[foPipelineTab]', standalone: true })
export class PipelineTabDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'fo-pipeline-layout',
  standalone: true,
  templateUrl: './template.html',
  styleUrls: ['./style.scss'],
  imports: [MaterialModule, CommonModule, I18NextModule, FontAwesomeModule],
})
export class PipelineLayoutComponent {
  @Input() filterCount$: Observable<number>;
  @Input() selectedTab: number;
  @Input() ticketCount: number;

  faXmark = faXmark;
  faFilter = faFilter;

  @ContentChildren(PipelineTabDirective) tabs!: QueryList<PipelineTabDirective>;
}
