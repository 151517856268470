<mat-expansion-panel
  [expanded]="openedIndex == index"
  (opened)="setOpenedExpansionPainelIndex(index)"
  *ngIf="travelForm"
  (closed)="openedIndex == -1 ? setOpenedExpansionPainelIndex(-2) : null"
>
  <mat-expansion-panel-header>
    <mat-panel-title class="travel-card-title">
      <mat-icon class="green-icon" *ngIf="isNew">add_circle</mat-icon>
      <mat-icon class="blue-icon" *ngIf="!isNew">airplanemode_active</mat-icon>
      <div *ngIf="isNew; else travelTitle">
        {{ 'forms:addNewTrip' | i18nextCap }}
      </div>
      <ng-template #travelTitle>
        {{ moment(travel.startingHour).format('DD/MM/YYYY') }}
      </ng-template>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <form class="row no-gutters" [formGroup]="travelForm">
    <!-- Assignees -->
    <div class="col-md-4">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{ 'forms:assignees' | i18nextCap }}</mat-label>
        <mat-select formControlName="assignees" multiple>
          <mat-option
            *ngFor="let user of assignees$ | async"
            [value]="user.id"
            >{{ user.firstName + ' ' + user.lastName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Distance -->
    <div class="col-md-4">
      <div class="item-padding">
        <mat-form-field class="d-block" appearance="outline">
          <mat-label>{{ 'forms:distance' | i18nextCap }}</mat-label>
          <input
            matInput
            formControlName="distance"
            type="number"
            min="0"
            step="1"
          />
          <mat-error>{{
            'validation:youMustEnterAValue' | i18nextCap
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- Stay -->
    <div class="col-md-4">
      <div class="item-padding">
        <mat-checkbox formControlName="stay">{{
          'forms:stay' | i18nextCap
        }}</mat-checkbox>
      </div>
    </div>

    <!-- Start Date -->
    <div class="col-md-12">
      <crm-form-date
        [formGroup]="travelForm"
        [field]="startingHourField"
      ></crm-form-date>
    </div>

    <!-- EndDate -->
    <div class="col-md-12">
      <crm-form-date
        [formGroup]="travelForm"
        [field]="endingHourField"
      ></crm-form-date>
    </div>

    <div class="col-md-12">
      <div class="item-padding buttons-right">
        <app-button-v2
          *ngIf="!isNew"
          text="delete"
          (onClick)="removeTravel()"
          color="red"
          [isDisabled]="isDetailPage"
        ></app-button-v2>

        <app-button-v2
          *ngIf="!isNew"
          text="save"
          (onClick)="editTravel()"
          color="green"
          [isDisabled]="!travelForm.valid || !travelForm.dirty"
        ></app-button-v2>

        <app-button-v2
          *ngIf="isNew"
          text="create"
          (onClick)="createTravel()"
          color="green"
          [isDisabled]="!travelForm.valid"
        ></app-button-v2>
      </div>
    </div>
  </form>
</mat-expansion-panel>
