<crm-expansion-panel
  title="{{ 'history' | i18nextCap }}"
  [hideIfNotAllowed]="historyPermissions"
>
  <ng-container *ngIf="history$ | async as history">
    <ng-container [ngSwitch]="history.state">
      <crm-error *ngSwitchCase="state.Error" [@inOut]="'in'"> </crm-error>
      <ng-container *ngSwitchCase="state.Loaded">
        <div class="timeline-container" [@inOut]="'in'">
          <ng-container *ngIf="historyList$ | async as historyList">
            <ul class="timeline" *ngIf="historyList.length">
              <li *ngFor="let historyItem of historyList">
                <ng-container [ngSwitch]="$any(historyItem).type">
                  <crm-history-activity
                    *ngSwitchCase="HistoryType.ACTIVITY"
                    [activity]="historyItem"
                    (openActivityEvent)="openActivityEvent.emit($event)"
                    (openTicketEvent)="openTicketEvent.emit($event)"
                  ></crm-history-activity>
                  <crm-history-ticket
                    *ngSwitchCase="HistoryType.SUPPORT"
                    [ticket]="historyItem"
                    (openActivityEvent)="openActivityEvent.emit($any($event))"
                    (openTicketEvent)="openTicketEvent.emit($event)"
                  ></crm-history-ticket>
                  <crm-history-ticket
                    *ngSwitchCase="HistoryType.REQUEST"
                    [ticket]="historyItem"
                    (openActivityEvent)="openActivityEvent.emit($any($event))"
                    (openTicketEvent)="openTicketEvent.emit($event)"
                  ></crm-history-ticket>
                  <crm-history-ticket
                    *ngSwitchCase="HistoryType.SALE"
                    [ticket]="historyItem"
                    (openActivityEvent)="openActivityEvent.emit($any($event))"
                    (openTicketEvent)="openTicketEvent.emit($event)"
                  ></crm-history-ticket>
                </ng-container>
              </li>
            </ul>
            <p class="no-activities" *ngIf="!historyList.length">
              {{ 'noActivities' | i18nextCap }}
            </p>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</crm-expansion-panel>
